import React, { Component } from 'react';
import './App.scss';
import Header from './components/Header';
import TableData from './TableData';
import VersionHistory from './VersionHistory';
import VersionHistoryNotes from './VersionHistoryNotes';
import {Button, Card, Container, Col, Row} from 'react-bootstrap';
import ipfsClient from 'ipfs-http-client';
import Modal from 'react-responsive-modal';
import {Form, FormGroup , Input, Label } from 'reactstrap';
import Axios from 'axios';

require('dotenv').config();
// const ipfs = new ipfsClient({ host: '127.0.0.1', port: '5001', protocol: 'http' });
const ipfs = new ipfsClient({
  host: process.env.REACT_APP_HOST,
  port:  process.env.REACT_APP_PORT,
  protocol:  process.env.REACT_APP_PROTOCOL,
});

const url = process.env.REACT_APP_API_URL;
//developed by akash






async function addToIpfs(file, comment,dctype) {
  let resp;

  const fileAdded = await ipfs.add({ content: file, wrapWithDirectory: true });
  console.log("Hash: " + fileAdded[0].hash);
  alert("Hash: " + fileAdded[0].hash);

const CreatedBy = sessionStorage.getItem("token")
 
  Axios.post(process.env.REACT_APP_API_URL+'add?fileName=' + file.name + '&hash=' + fileAdded[0].hash+'&category='+dctype+'&description='+comment+'&CreatedBy='+CreatedBy)
    .then(response => response.json())
    .then(response => {
      resp = response.data;
      console.log(resp);
    })
    .catch(err => console.error(err))

  await fetch(`${process.env.REACT_APP_API_URL}addComment?ParentID=` + resp.insertId + '&version=' + 1 + '&comment=' + comment)
    .catch(err => console.error(err))
  await window.location.reload();
}



export default class DocumentsLanding extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      fieldVal: "",
      selectedFile: "",
      selectedId: "",
      selectedFileID: null,
      selectedFileCreatedBy: "",
      open: false,
      categoryId: "",
      categoryList:[],
      fileUp: '',
      comment: "no comment",
      selectMenu:'',
      resObj:null,
      apikey:'zBBsffFEHrHmZ772cVE8LClg2hNeld'
    }
    this.changeFile = this.changeFile.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
  }


  componentDidMount() {
    console.log('component mounted');
    this.getCategories();
  }

  getCategories() {
    Axios.get(process.env.REACT_APP_API_URL + "getAllCategories").then(
      (categories) => {
        console.log(categories);
        this.setState({
          categoryList: categories.data
        });
      }
    );
  }


  onCategoryChange = (event) => {
    console.log(event.target.value)
    this.setState({
      categoryId: event.target.value
    })

    if(event.target.value === 'all') {
      this.setState({categoryId: ''})
    }
  }
  // changeFile = (newFileName, newId) => {
  //   this.setState({
  //     selectedFile: newFileName,
  //     selectedId: newId

  //   },
  //     () => console.log(this.state.selectedFile, this.state.selectedId));
  // }

  changeFile = (newFileName, newFileID, newFileCreatedBy) => {
    this.setState({
      selectedFile: newFileName,
      selectedFileID: newFileID,
      selectedFileCreatedBy: newFileCreatedBy
      
    },
      () => {
        console.log(this.state.selectedFile);

      });
    //console.log("In DL: "+this.state.selectedFile);

  }





  onOpenModal = () => {

    this.setState({
      open: true,
      // selectedFile:fileName,
      // selectedVersion:versionNo,
      // createdBy:createdBy
    }
    );
  };

  onFileChange = (e) => {
    this.setState({
      fileUp: e.target.files[0]
    }, () => console.log(this.state.fileUp)
    )

  }
  onSelectChange =(e)=>{
this.setState({
  selectMenu : e.target.value
},()=>console.log(this.state.selectMenu))
  }

  onCloseModal = () => {

    this.setState({ open: false });
  };

  handleChangeComment = e => {
    this.setState({
      [e.target.name]: e.target.value,

    }, () => console.log(this.state.comment)
    )
  }

  render() {
    const { open } = this.state;
    return (

      <div className="App">

        <Header />
        <Container as="div" fluid>
          <div className="app-layout pattern">
            <Card as="div">
              <Card.Body as="div">
                <Card.Title as="div">
                  <Row as="div">
                    <Col as="div" className="page-title">All Documents</Col>
                    <Col as="div"> 
                    <div className="form-group">
                      <label htmlFor="category">
                        Sort By Category
                      </label>
                      <select name="" id="" className="form-control" onChange={this.onCategoryChange}>
                        <option selected value="all">All Categories</option>
                        {this.state.categoryList.map((tile, i) => (
                        <option value={tile.id} > {tile.cateoryName}</option>
                        ))}
                      </select>
                      </div>
                    </Col>
                    <Col as="div" className="text-right">
                      <Button variant="primary" onClick={this.onOpenModal}>Upload</Button>
                    </Col>
                  </Row>
                </Card.Title>
                <Card.Text as="div">
                  <Row as="div">
                    <Col sm={7} as="div">
                      <div className="left-panel data-content">
                        <TableData triggerChangeFile={this.changeFile} categoryId={this.state.categoryId} />
                      </div>
                    </Col>
                    <Col as="div" sm={5} className="right-panel data-content">
                      {/* <VersionHistory selectedFile={this.state.selectedFile} selectedId={this.state.selectedId} /> */}
                      <VersionHistory selectedFile={this.state.selectedFile} selectedFileID={this.state.selectedFileID} selectedFilecreatedBy={this.state.selectedFileCreatedBy} />
                      <VersionHistoryNotes />
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Container>

        <Modal as="div" open={open} onClose={this.onCloseModal} center>
          <h2>Add New File</h2>
          <hr />
          <Form as="div">

            <FormGroup as="div">

              <Input type="file" id="fileUp" name="fileUp" onChange={this.onFileChange} />

            </FormGroup>
            <FormGroup as="div">
          <Label for="exampleSelect">Document Category :</Label>
          <Input type="select" name="select" id="exampleSelect" onChange={this.onSelectChange}>
           <option>- - -  Please Select  - - -</option>
            <option>Agreement Letter</option>
            <option>Contract</option>
            <option>Employee Offer letter</option>
            <option>Employee on boarding</option>
            <option>Evaluation Forms</option>
            <option>Insurance Forms</option>
            <option>Invoice</option>
            <option>Non-disclosure Agreement</option>
            <option>Proposal</option>
            <option>Purchase Order</option>
            <option>Seperation Agreement</option>
            <option>Statement of work</option>
            <option>work Order</option>
            <option>Other</option>

          </Input>
        </FormGroup>
            <FormGroup as="div">

              <Input type="textarea" name="comment" placeholder="Add a note for this version..." cols="500" rows="10" onChange={this.handleChangeComment} />
            </FormGroup>

            <FormGroup as="div">
              <Col as="div" className="text-right">
                <Button name="submitbtn" className="btn btn-primary" onClick={() => addToIpfs(this.state.fileUp, this.state.comment, this.state.selectMenu)}>Add</Button>
              </Col>

            </FormGroup>

          </Form>
        </Modal>

      </div>
    )
  }
}



