import "./App.scss";
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DocumentsLanding from "./DocumentsLanding";

// import Posts from './components/posts'
// import Postform from './components/postform';
// import { Provider } from 'react-redux'
// import store from './store'
// import TableData from './components/TableData';
import Login from "./components/Login";
import Admin from "./components/Admin";
import Logout from "./components/Logout";
import DocumentsLandingMail from "./DocumentLandingMail";
// import  Signers  from './Signers';
import Signers from "./Signers";
import DashboardLanding from "./DashboardLanding";
import CompletedDocs from "./CompletedDocs";
import Awaiting from "./Awaiting";
import NewSignature from "./NewSignature";
import Register from "./components/Register";
import Login1 from "./components/NewLogin";
import checkingsigner from "./checkingsigner";
import TableDatamail from "./CheckMail";
import SignEasySignature from "./signeasysignature";
import downloadFile from "./downloadfile";
import SignerDocument from "./signerDocument";
import NavbarPage from "./asign";
// import { placeholder } from '@babel/types';
import Placeholder from "./placeholder";
import Thanks from "./Thanku";
import Category from "./Category";
import AwaitingOthers from "./AwaitingOthers";
import Users from "./Users";
import PlaceholderLanding from "./PlaceholderLanding";
import Template from "./Template";
import toastr from 'reactjs-toastr';
import 'reactjs-toastr/lib/toast.css';
import Axios from 'axios';
import LandingPage from './LandingPage';

Axios.interceptors.request.use(function (config) {
  console.log(config);
  const token = sessionStorage.getItem('token');
  config.headers.Authorization =  'Bearer ' + token;

  return config;
});
class App extends Component {


  constructor(props) {
    super(props)
  
    
  }



  render() {
    return (
      <Router>
        <Switch>
        <Route exact path="/" component={LandingPage} />{" "}
          <Route exact path="/login" component={Login1} />{" "}
          <Route path="/admin" component={Admin} />{" "}
          <Route path="/logout" component={Logout} />{" "}
          <Route path="/demo" component={DocumentsLandingMail} />{" "}
          <Route path="/documents" component={DocumentsLanding} />{" "}
          <Route path="/signature" component={Signers} />{" "}
          <Route path="/Newsignature" component={NewSignature} />{" "}
          <Route path="/dash" component={DashboardLanding} />{" "}
          <Route path="/completeddocs" component={CompletedDocs} />{" "}
          <Route path="/awaitingsigneddocs" component={Awaiting} />{" "}
          <Route path="/register" component={Register} />{" "}
          <Route path="/login" component={Login} />{" "}
          <Route path="/mail" component={checkingsigner} />{" "}
          <Route path="/mail1" component={TableDatamail} />{" "}
          <Route path="/EasySignature" component={SignEasySignature} />{" "}
          <Route path="/home" component={downloadFile} />{" "}
          <Route path="/signingdocument" component={SignerDocument} />{" "}
          <Route path="/navbar" component={NavbarPage} />{" "}
          <Route path="/placeholderlanding" component={PlaceholderLanding} />{" "}
          <Route path="/placeholder" component={Placeholder} />{" "}
          <Route path="/template" component={Template} />{" "}
          <Route path="/thanks" component={Thanks} />{" "}
          <Route path="/category" component={Category} />{" "}
          <Route path="/awaitingOthers" component={AwaitingOthers} />{" "}
          <Route path="/users" component={Users} />{" "}
          {/* <Route path='posts' component={posts}/> */}{" "}
        </Switch>{" "}
        {/* <Provider store={store}>
                                          <div className="App">
                                            <TableData/>
                                            <hr/>
                                            <Postform />
                                            <hr />
                                            <Posts />
                                          </div>
                                        </Provider> */}{" "}
      </Router>
    );
  }
}

export default App;
