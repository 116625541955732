import React from 'react';
import {  Card, Col, Container, Row } from 'react-bootstrap';


class VersionHistoryMail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Versiondetails: [],
      posts: [],
      IDFiles: '',
      nowFile:'intial null',
      nowId:'initial null'
    }
    this.handleDoubleClick = this.handleDoubleClick.bind(this);
  }

  handleDoubleClick = (e, versionFile,fileHash) => {
    this.setState({
      versionFile:versionFile,
      fileHash:fileHash
    });
      window.location.href = process.env.REACT_APP_API_URL + fileHash;
  }

  componentWillReceiveProps(props) {
    this.setState({
      nowFile: props.selectedFile,
      nowId: props.selectedId
    },
      () => {console.log(this.state.nowFile)
        fetch(`${process.env.REACT_APP_API_URL}versionMaillist?fileName=` + this.state.nowFile + '&id=' + this.state.nowId)
          .then(response => response.json())
          .then(response => this.setState({ Versiondetails: response.data }))
          .catch(err => console.error(err))

      }
    )
  }

  render() {

    return (

      this.state.Versiondetails.map((item, index) =>

        <React.Fragment >

          <Container className="card-list-items" key={index} >
            <Row className="selected">
              <Col><span className="date" >{`${item.UpdatedAt}`}</span></Col>
              <Col sm={1}>
                <span className="version-count" onDoubleClick={(e) => this.handleDoubleClick(e, item.fileName,item.hash)}>
                  <i className="triangle" />
                  <span position="righttop">v{`${item.version}`}</span>
                </span>
              </Col>
              <Col>
                <Card className="v-card" style={{ width: '300px' }}>
                  <Card.Body>
                    <Card.Text>
                      <Row>
                        <Col>
                          <label>File Name: </label>
                          <span>{`${item.fileName}`}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>Created By: </label>
                          <span>{`${item.CreatedBy}`}</span>
                        </Col>
                      </Row>
                    </Card.Text>
                    {/* <Card.Link href="#">Add Note</Card.Link> */}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </React.Fragment>

      )

    )

  }

}

export default VersionHistoryMail;
