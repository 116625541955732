import React, { Component } from 'react'
// import Iframe from 'react-iframe'
import { Button } from 'react-bootstrap';
import Header from './components/Header'
import Placeholder from './placeholder';
import axios from 'axios';
import Image from 'react-bootstrap/Image'
import VersionHistory from './VersionHistory';
import VersionHistoryNotes from './VersionHistoryNotes';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';    
import 'react-toastify/dist/ReactToastify.css';

import { Card, Container, Col, Row } from 'react-bootstrap';

let container;

export default class SignEasySignature extends Component {
    constructor(props) {
        super(props)
        if (this.props.location.state === undefined) {
            this.props.history.push({
                pathname: "/dash",
            })
            window.location.reload()
        }

        console.log(this.props)
        // By Yash T (Changes)
        this.state = {
            hash: this.props.location.state.hash,
            id: this.props.location.state.fileid,
            fileName: this.props.location.state.fileName,
            x_cord: null,
            y_cord: null,
            selectedsigner: [],
            pageNo: null,
            file: '[]',
            fileObj: [],
            firstName: [],
            lastName: [],
            uploadButton: true
        }
        console.log(this.state.id);
        // By Yash T
        this.checkIfSignatureIsUploaded();
        this.checkForNextSigners = this.checkForNextSigners.bind(this)
    }
    // By Yash T

    notify = () => toast("Wow so easy!");

    triggersigner = async (x, y, pageno, actualx, actualy) => {
        await this.setState({
            x_cord: x,
            y_cord: y,
            pageNo: pageno,
            actualxcord: actualx,
            actualycord: actualy,
        })
    }


    checkForNextSigners() {
        axios.get(process.env.REACT_APP_API_URL + 'checkForNextSigners?fileId=' + this.state.id).then(signer => {
            console.log(signer);
        }).catch(err => {
            console.log(err);
        })
    }


    // By Yash T
    checkIfSignatureIsUploaded = () => {
        axios.get(`${process.env.REACT_APP_API_URL}checkSignatureUrl?token=` + sessionStorage.getItem('email')).then(res => {
            // const data = res.json();
            console.log(res);
            // console.log(data);
            if (res.data.signature !== null) {
                this.setState({ uploadButton: false })
                this.setState({ file: res.data.signature });
                // this.state.file = JSON.stringify(res.signature);
                this.setState({ firstName: res.data.firstName, lastName: res.data.lastName });
            } else if (res.data !== null) {
                if (res.data.signature !== null) {
                    this.setState({ uploadButton: false })
                } else {
                    this.setState({ uploadButton: true })
                }
                this.setState({ firstName: res.data.firstName, lastName: res.data.lastName });
            } else {
                if (res.data.signature !== null) {
                    this.setState({ uploadButton: false })
                } else {
                    this.setState({ uploadButton: true })
                }

                console.log('No Data found');
            }
        }, error => {
            console.log(error);
        })
    }

    // By Yash T (Test)
    // getDocument = () => {
    //     axios.get('http://localhost:4000/get').then((res) => {
    //         console.log(res);
    //         return res;
    //     }).catch(error => {

    //     })
    // }

    // By Yash T
    uploadSignature = () => {
        // axios.post('http://localhost:4000/uploadSignature?token' + sessionStorage.getItem('token')).then(res => {

        // }, err => {
        //     console.log(err);
        // })
        var bodyFormData = new FormData();
        bodyFormData.append('image', this.state.fileObj);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}uploadSignature`,
            data: bodyFormData,
            params: {
                token: sessionStorage.getItem('email')
            },
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                console.log(response);
               toast.success('Uploaded Signature Successfully')
                // container.success('Succesfully Uploaded')
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    }

    // By Yash T
    fileUpload = event => {

        // Update the state
        console.log(event.target.files);
        this.setState({ file: URL.createObjectURL(event.target.files[0]), fileObj: event.target.files[0] });
        this.setState({ uploadButton: true });
    };


    // Sign Document
    // Yash T

    signDocument = () => {

        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}attachSignatureToFile`,
            data: {},
            params: {
                id: this.state.id,
                // token: sessionStorage.getItem('')
            },
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                if(response.data.status){

                
                if (response.data.fileAddress) {

                    window.open(response.data.fileAddress.fileUrl);
                    return response.data.fileAddress
                    // this.checkForNextSigners();
                } 
                toast.success('Document Signed Successfully')
            } else {
                toast.error('Error !')
            }


                console.log(response);
            }).then(data => {
              
                this.checkForNextSigners();
            })
            .catch(function (response) {
                //handle error
                toast.error('Error !');
                console.log(response);
            });
    }



    render() {
        return (
            <div className="App">
                  <ToastContainer />
                {/* <ToastContainer
                    ref={ref => container = ref}
                    className="toast-top-right"
                /> */}
                <Header />

                <Container as="div" fluid>

                    <div className="app-layout pattern">
                        <Card as="div">
                            <Card.Body as="div">
                                <Card.Title as="div">
                                    <Row as="div">
                                        <Col as="div" className="page-title">Proceed To SignA Document... </Col>

                                    </Row>
                                </Card.Title>
                                <Card.Text as="div">
                                    <Row as="div">
                                        {/* <Col sm={12}>
                                            <React.Fragment>
                                                <Iframe url={this.props.location.state.signeasy_url}
                                                    width="80%"
                                                    height="700 px"
                                                    id="myId"
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative" />

                                            </React.Fragment>
                                        </Col> */}
                                        <Col as="div" sm={7}>
                                            <div className="left-panel data-content">
                                                <Placeholder selectedsigner={this.state.selectedsigner} hash={this.state.hash} triggerselectsigner={this.triggersigner} />
                                            </div>
                                        </Col>

                                        <Col as="div" sm={4}>
                                            {/* <VersionHistory selectedFile={this.state.selectedFile} selectedId={this.state.selectedId} /> */}
                                            <VersionHistory selectedFile={this.state.fileName} selectedFileID={this.state.id} selectedFilecreatedBy={this.state.selectedFileCreatedBy} />
                                            <VersionHistoryNotes />
                                            <ListGroup as="div">
                                                <h5 style={{ fontFamily: "monospace" }}>Check Details:</h5>
                                                <h5><strong>FullName</strong></h5>
                                                <ListGroupItem as="div">{this.state.firstName} {this.state.lastName}</ListGroupItem>
                                                {/* <h5><strong>X-Cord:-</strong></h5>
                                                <ListGroupItem>{this.state.x_cord}</ListGroupItem>
                                                <h5><strong>Y-Cord:-</strong></h5>
                                                <ListGroupItem>{this.state.y_cord}</ListGroupItem>
                                                <h5><strong>page_no</strong></h5>
                                                <ListGroupItem>{this.state.page_no}</ListGroupItem> */}

                                                <input type="file" onChange={this.fileUpload} />

                                                <div className="">
                                                    <Image width="150px" src={this.state.file} fluid />
                                                </div>
                                                {this.state.uploadButton && <Button variant="primary" type="submit" style={{ width: "100%", height: "43px" }} className="button1" onClick={this.uploadSignature} >
                                                    <span style={{ fontSize: "14px", fontStyle: "bold" }} >Upload Signature</span>
                                                </Button>}
                                                <Button variant="primary" type="submit" style={{ width: "100%", height: "43px" }} className="button1" onClick={this.signDocument} >
                                                    <span style={{ fontSize: "14px", fontStyle: "bold" }} >Sign A Document</span>
                                                </Button>
                                            </ListGroup>




                                        </Col>



                                    </Row>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </Container>
            </div>


        )
    }
}
