import React, { Component } from 'react'
import '../src/assets/scss/_header.scss';
import {Button, Dropdown, DropdownButton, Form, FormControl, Image, Navbar, Nav} from 'react-bootstrap';
import logo from '../src/assets/images/innowise-logo.png';


export default class NavBar extends Component{
    constructor(props) {
        super(props);

        this.state = {};

        this.handleScroll = this.handleScroll.bind(this);
    }

    handleScroll() {
        this.setState({scroll: window.scrollY});
    }
  
  componentDidMount() {
        const el = document.querySelector('nav');
        this.setState({top: el.offsetTop, height: el.offsetHeight});
        window.addEventListener('scroll', this.handleScroll);
    }
  
  componentDidUpdate() {
        this.state.scroll > this.state.top ? 
            document.body.style.paddingTop = `${this.state.height}px` :
            document.body.style.paddingTop = 0;
    }
  
  render() {
    return (
        <div className="header" >
      {/* <nav className={this.state.scroll > this.state.top ? "fixed-nav" : ""}>
        <ul>
          <li>Home</li>
          <li>About</li>
          <li>Careers</li>
          <li>Contact</li>
          <li>Help</li>
        </ul>
      </nav> */}
      <Navbar id="navbar" expand="lg">
        <Navbar.Brand>
          <Image className="app-logo__image" src={logo} alt="Versa Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            // activeKey="/documents"
            onSelect={selectedKey => alert(`selected ${selectedKey}`)}
            className={this.state.scroll > this.state.top ? "fixed-nav" : ""}>
            <Nav.Link href="/home">Home</Nav.Link>
            <Nav.Link href="/documents">Documents</Nav.Link>
            <Nav.Link href="/dash">Dashboard</Nav.Link>
            <Nav.Link href="/logout">Logout</Nav.Link>          

          </Nav>         
        </Navbar.Collapse>
      </Navbar>
{/* <div id="app"></div> */}
<div className="content">
  <div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Provident aliquam itaque, ab optio, omnis cupiditate ut accusamus alias, quae vitae ducimus animi autem aperiam qui in asperiores dolorum? Adipisci, sit!</div>
  <div>Dolorem nesciunt enim odit, voluptate maxime corporis, odio assumenda provident neque dolor atque minima ad! At rem laborum ullam deserunt in est veniam asperiores corrupti, ea optio, magni fugiat nulla.</div>
  <div>Qui, quo sunt fuga autem beatae animi error nemo commodi ipsam dolore, mollitia dolorem atque, quas nesciunt? At, nam quaerat accusantium adipisci explicabo nulla a quidem quas ea fugiat esse?</div>
  <div>Quam aut, excepturi eveniet illum facere officia doloremque et ullam iusto. Iure cum sapiente, culpa nobis nemo accusamus tempora voluptatibus, id odit impedit officia itaque totam. Deserunt nemo voluptates nihil!</div>
  <div>Minus quaerat, blanditiis impedit quam fugiat nostrum maxime quisquam. Laboriosam nostrum accusantium saepe exercitationem, temporibus blanditiis facere non iusto enim repellendus suscipit corporis eveniet commodi maxime nihil tenetur itaque voluptatibus.</div>
  <div>Veniam quos error mollitia molestias esse sapiente cumque fugiat id obcaecati ex repellat optio eaque commodi nam nesciunt beatae tempore, autem illum aspernatur vero et numquam. Laborum animi similique quos.</div>
  <div>Eveniet quasi est voluptates minima, ipsum possimus consectetur. Quo, vel. Qui, beatae unde at amet illo tempore molestias saepe quidem quo voluptatum. Ea amet cum doloremque eos iste accusamus sunt!</div>
  <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem rem vel explicabo consequatur perspiciatis accusamus esse, eligendi, alias eum ad veniam, fuga minima nisi expedita eius! Officia vel ullam laudantium?</div>
  <div>Dolore temporibus quidem velit, soluta eligendi voluptatibus, adipisci labore id non reiciendis libero alias in a omnis asperiores recusandae atque cum nisi repellendus error itaque? Numquam molestias ab labore aliquid.</div>
  <div>Aliquid sequi rem quasi facere alias quae, optio soluta adipisci illum dolore vitae? Ipsum, provident iure aliquid ut aspernatur, non, sint necessitatibus itaque veniam voluptas eos temporibus asperiores voluptatum vero.</div>
  <div>Porro laudantium fugit aperiam hic explicabo cupiditate dicta dolorum, corrupti ipsam sed labore adipisci maiores voluptates tenetur. Explicabo, voluptates. Rerum alias quis facere quia saepe aperiam molestiae iusto ea modi.</div>
  <div>Dolore possimus ipsa, praesentium iusto, esse sed, qui reprehenderit incidunt sequi eveniet cum. Aspernatur architecto doloremque eius quaerat alias debitis blanditiis harum, nesciunt modi quae maiores quo dolorum numquam omnis.</div>
  <div>Reiciendis corporis quo doloribus tempora enim exercitationem possimus iure alias qui illo, totam culpa voluptas laudantium beatae. Voluptatibus, saepe? Perferendis possimus explicabo id et assumenda aut consequuntur animi officiis facilis?</div>
  <div>Error, eligendi molestiae quaerat dolorum quidem vitae nulla maiores adipisci corrupti laudantium eos magni, nesciunt, accusantium iste dolore vero libero fugiat perspiciatis. Odio voluptate asperiores repellat maiores nemo accusamus esse.</div>
</div>
      </div>
    );
  }
}

  
