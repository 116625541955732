import React, { Component } from "react";
import { Button, Fade } from "react-bootstrap";
import ReactTable from "react-table";
import Header from "./components/Header";
import { Card, Container, Col, Row } from "react-bootstrap";

class CompletedDocs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      CompletedDocs: [],
    };
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}CompletedDocs`)
      .then((response) => response.json())
      .then((response) => this.setState({ CompletedDocs: response.data }))
      .catch((err) => console.error(err));
  }

  render() {
    const columns = [
      {
        id: "col1",
        columns: [
          {
            Header: "Document Name",
            id: "sub_col_1a",
            accessor: (d) => {
              return (
                <div>
                  {/* <div className="document-title" id={d.id} onClick={(e) => this.reply_click(e, d.id,d.fileName)} style={{ cursor: 'pointer' }} >{d.fileName}</div> */}
                  <div
                    className="document-title"
                    id={d.id}
                    style={{ cursor: "pointer" }}
                  >
                    {d.fileName}
                  </div>
                  <span class="document-id">{d.hash}</span>
                </div>
              );
            },
            width: 500,
          },
        ],
      },
      {
        Header: "Version",
        accessor: "version",
        width: 100,
      },
      {
        Header: "Created By",
        accessor: "CreatedBy",
        width: 100,
      },

      {
        Header: "Last Modified",
        accessor: "UpdatedAt",
        width: 300,
      },
      {
        Header: "",
        accessor: "moreactionitems",
        width: 50,
        sortable: false,
        className: "more-action-items-td",
        Cell: (row) => {
          return (
            <div className="more-action-items-td">
              <Button variant="outline-light" className="custom-icon">
                <span className="icon-three-dot" />
              </Button>
              <Fade>
                <div className="action-links">
                  <Button variant="outline-light" title="Download">
                    <span className="icon-download"></span>
                  </Button>
                  <Button variant="outline-light" title="Duplicate">
                    <span className="icon-duplicate"></span>
                  </Button>
                  <Button variant="outline-light" title="Edit">
                    <span className="icon-edit"></span>
                  </Button>
                </div>
              </Fade>
            </div>
          );
        },
      },
    ];

    return (
      <div className="App">
        <Header />
        <Container fluid>
          <div className="app-layout pattern">
            <Card>
              <Card.Body>
                <Card.Title>
                  <Row>
                    <Col className="page-title">Completed Documents </Col>
                  </Row>
                </Card.Title>
                <Card.Text>
                  <Row>
                    <Col sm={12}>
                      <React.Fragment>
                        <ReactTable
                          data={this.state.CompletedDocs}
                          columns={columns}
                          // defaultPageSize={4}
                          style={{
                            height: "500px"
                          }}
                          showPagination={false}
                          resizable={false}
                          defaultSorted={[{ id: "UpdatedAt", desc: false }]}
                        />
                      </React.Fragment>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    );
  }
}

export default CompletedDocs;
