import React, { Component } from 'react'
import Header from '../src/components/Header';
import { Card, Container, Col, Row, Button } from 'react-bootstrap';

export default class NewSignature extends Component {
    render() {
        return (
          <div className="App">
    
          <Header />
          <Container fluid>
            <div className="app-layout pattern">
              <Card>
                <Card.Body>
                  <Card.Title>
                    <Row>
                      <Col className="page-title">All Documents (38)</Col>
                      <section>
            <p>
            </p><section>
              <div className="container-fluid">
                <div className="row clearfix">
                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="card product_item">
                      <div className="body">
                        <div className="cp_img">
                          <img src="assets/images/ecommerce/1.png" alt="Product" className="img-fluid" />
                          <div className="hover">
                            <a href="javascript:void(0);" className="btn btn-primary btn-sm waves-effect"><i className="zmdi zmdi-cloud-download" /></a>
                            <a href="javascript:void(0);" className="btn btn-warning btn-sm waves-effect"><i className="zmdi zmdi-comment-edit" /></a>
                            <a href="javascript:void(0);" className="btn btn-danger btn-sm waves-effect"><i className="zmdi zmdi-delete" /></a>
                          </div>
                        </div>
                        <div className="product_details">
                          <h5><a href="ec-product-detail.html">Test Document.pdf</a></h5>
                          <ul className="product_price list-unstyled">
                            <li className="old_price">2 MB</li>
                            <li className="new_price">Dec 11, 2017</li>
                          </ul>
                        </div>
                      </div>
                    </div>                
                  </div>
                  <div className="col-lg-5 col-md-4 col-sm-12">
                    <div className="body">
                      <form>
                        <label htmlFor="email_address">Document Name</label>
                        <div className="form-group">   
                          <textarea name="docName" id="doc_name" className="form-control" placeholder="Test Document" cols={40} rows={1} defaultValue={"Test Document"} />
                        </div>
                        <label htmlFor="password">Document Category</label>														
                        <select className="form-control show-tick">
                          <option value>-- Please select --</option>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={30}>30</option>
                          <option value={40}>40</option>
                          <option value={50}>50</option>
                        </select>
                        <label htmlFor="email_address">Document Description</label>
                        <div className="form-group">  
                          <textarea name="docDesc" id="doc_name" className="form-control" placeholder="Document Description" cols={40} rows={5} defaultValue={""} />															
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-5 col-md-12">
                    <div className="card member-card">
                      <div className="header l-parpl" style={{minHeight: '90px'}}>
                        <h4 className="m-t-0">Signers</h4>
                      </div>
                      <div className="member-img" style={{marginTop: '-36px'}}>
                        <a href="profile.html" className>
                          <img className="rounded-circle" src="assets/html/images/signature.png" alt="profile-image" style={{width: '78px'}} />
                        </a>
                      </div>
                      <div className="body">
                        <div className="col-12">
                          <select className="form-control show-tick">
                            <option value>-- Please select --</option>
                            <option value={10}>Anindya Chatterjee</option>
                          </select>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-4">
                            <h5><i style={{color: '#888888', fontSize: '-webkit-xxx-large'}} className="zmdi zmdi-help" /></h5>
                            <small>Help</small>
                          </div>
                          <div className="col-4">
                            <h5><button className="btn btn-info btn-round waves-effect" type="button">ADD</button></h5>																
                          </div>
                          <div className="col-4">
                            <h5><i style={{color: '#104775', fontSize: '-webkit-xxx-large'}} className="zmdi zmdi-eye" /></h5>
                            <small>Add Observation</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>											
                </div>										
              </div>
            </section>
          </section>
                    </Row>
                  </Card.Title>
                  <Card.Text>
                    <Row>
                      <Col sm={12}>
                        <div className="left-panel data-content">
    
                        </div>
                      </Col>
                     
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Container>
    
       
    
        </div>
        )
      }
}