import React from "react";
import Modal from "react-responsive-modal";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import ipfsClient from "ipfs-http-client";

import { Card, Col, Container, Row } from "react-bootstrap";
import { create } from "peer-info";
import VersionHistoryNotes from "./VersionHistoryNotes";
import Axios from "axios";


const ipfs = new ipfsClient({
  host: process.env.REACT_APP_HOST,
  port:  process.env.REACT_APP_PORT,
  protocol:  process.env.REACT_APP_PROTOCOL,
});


// const ipfs = new ipfsClient({
//   host: "127.0.0.1",
//   port: "5001",
//   protocol: "http",
// });
//var selectedUpFile = 'none';

async function AddVersion(
  selectedUpFile,
  currentFile,
  currentFileID,
  versionComment
) {
  let ver, newVer;
  const fileAdded = await ipfs.add({
    content: selectedUpFile,
    wrapWithDirectory: true,
  });
  console.log("Hash: " + fileAdded[0].hash);
  alert("Hash: " + fileAdded[0].hash);

  await fetch(
    `${process.env.REACT_APP_API_URL}versionlist?fileName=` +
      currentFile +
      "&id=" +
      currentFileID
  )
    .then((response) => response.json())
    .then((response) => {
      ver = response.data.length;
      console.log(response.data);
      console.log(response.data.length);
    })

    .catch((err) => console.error(err));
  newVer = ver + 1;

  await fetch(
    `${process.env.REACT_APP_API_URL}addVer?fileName=` +
      currentFile +
      "&hash=" +
      fileAdded[0].hash +
      "&version=" +
      newVer +
      "&parentID=" +
      currentFileID
  )
    // .then(response => this.setState({ posts: response.data }))
    .catch((err) => console.error(err));

  await fetch(
    `${process.env.REACT_APP_API_URL}comment?ParentID=` +
      currentFileID +
      "&version=" +
      newVer +
      "&comment=" +
      versionComment
  )
    // .then(response => this.setState({ posts: response.data }))
    .catch((err) => console.error(err));

  //alert(ver)
  //alert(ver);
  window.location.reload();
}

class versionHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openComment: false,
      Versiondetails: [],
      posts: [],
      IDFiles: "",
      nowFile: "none",
      nowFileID: null,
      nowFileCreatedBy: "",
      fileUp: "",
      selectedVersion: "none",
      comment: "no comment",
      commentTime: null,
      selectedVersionForNote: null,
      fetchComment: [],
      addCommentText: null,

      // note: '',
      // selectedFile:'',
      // selectedVersion:'',
      // createdBy:''
    };
    this.handleDoubleClick = this.handleDoubleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.handleVersionClick = this.handleVersionClick.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState(
      {
        nowFile: props.selectedFile,
        nowFileID: props.selectedFileID,
        nowFileCreatedBy: props.selectedFilecreatedBy,
      },
      () => {
        console.log(this.state.nowFileID);

        Axios.get(
          `${process.env.REACT_APP_API_URL}versionlist?fileName=` +
            this.state.nowFile +
            "&id=" +
            this.state.nowFileID
        )
          // .then((response) => response.json())
          .then((response) => this.setState({ Versiondetails: response.data }))
          .catch((err) => console.error(err));
      }
    );
  }
  handleDoubleClick = (e, versionFile, fileHash) => {
    this.setState({
      versionFile: versionFile,
      fileHash: fileHash,
    });
    window.location.href = process.env.REACT_APP_API_URL + fileHash;
  };

  handleVersionClick = (e, version) => {
    this.setState(
      {
        selectedVersion: version,
      },
      () => {
        fetch(
          `${process.env.REACT_APP_API_URL}selectComment?parentID=` +
            this.state.nowFileID +
            "&version=" +
            this.state.selectedVersion
        )
          .then((response) => response.json())
          .then((response) =>
            this.setState({ fetchComment: response.data }, () =>
              console.log(this.state.fetchComment)
            )
          )
          .catch((err) => console.error(err));
      }
    );
  };

  onFileChange = (e) => {
    this.setState(
      {
        fileUp: e.target.files[0],
      } //,()=>{
      // selectedUpFile=this.state.fileUp;
      // console.log(selectedUpFile);
      // }
    );
  };

  onOpenModal = () => {
    this.setState({
      open: true,
      // selectedFile:fileName,
      // selectedVersion:versionNo,
      // createdBy:createdBy
    });
  };

  onOpenCommentModal = (selectedV) => {
    this.setState(
      {
        openComment: true,
        selectedVersionForNote: selectedV,
        // selectedFile:fileName,
        // selectedVersion:versionNo,
        // createdBy:createdBy
      },
      () => console.log(this.state.selectedVersionForNote)
    );
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };
  onCloseCommentModal = () => {
    this.setState({ openComment: false });
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => console.log(this.state.note)
    );
  };

  handleChangeComment = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => console.log(this.state.addCommentText)
    );
  };

  addNote = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}addComment?ParentID=` +
        this.state.nowFileID +
        "&version=" +
        this.state.selectedVersionForNote +
        "&comment=" +
        this.state.addCommentText
    )
      // .then(response => this.setState({ posts: response.data }))
      .catch((err) => console.error(err));

    this.setState(
      {
        openComment: false,
      },
      () => console.log
    );
  };

  render() {
    const { open } = this.state;
    const { openComment } = this.state;
    if (this.state.nowFile === "none") {
      return (
        <Container as="div">
          <Col as="div">
            <h4>Please select a file to see its version details...</h4>
          </Col>
        </Container>
      );
    } else {
      return (
        <div>
          {this.state.Versiondetails.map((item, index) => (
            <div>
              <Container as="div" className="card-list-items" key={index}>
                <Row as="div" className="selected">
                  <Col as="div">
                    <span className="date">{`${item.UpdatedAt}`}</span>
                  </Col>
                  <Col as="div" sm={1}>
                    <span
                      className="version-count"
                      onClick={(e) => this.handleVersionClick(e, item.version)}
                      onDoubleClick={(e) =>
                        this.handleDoubleClick(e, item.fileName, item.hash)
                      }
                    >
                      <i className="triangle" />
                      <span position="righttop">v{`${item.version}`}</span>
                    </span>
                  </Col>
                  <Col as="div">
                    <Card
                      as="div"
                      className="v-card"
                      style={{ width: "300px" }}
                    >
                      <Card.Body as="div">
                        <Card.Text as="div">
                          <Row as="div">
                            <Col as="div">
                              <label>Version: v{item.version}</label>
                              <span>
                                <Card.Link
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.onOpenCommentModal(item.version)
                                  }
                                >
                                  Add Note
                                </Card.Link>
                              </span>
                            </Col>
                            <Col></Col>
                          </Row>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>

              <div>
                <Modal as="div" open={open} onClose={this.onCloseModal} center>
                  <h2>Add Version</h2>
                  <hr />
                  <Form as="div">
                    <FormGroup as="div">
                      <Input
                        type="file"
                        id="file"
                        name="ipfile"
                        onChange={this.onFileChange}
                      />
                    </FormGroup>

                    <FormGroup as="div">
                      <Input
                        type="textarea"
                        name="note"
                        placeholder="Add a note for this version..."
                        cols="500"
                        rows="10"
                        onChange={this.handleChange}
                      />
                    </FormGroup>

                    <FormGroup as="div">
                      <Col as="div" className="text-right">
                        {/* <Button
                          name="submitbtn"
                          className="btn btn-primary"
                          onClick={() =>
                            AddVersion(
                              this.state.fileUp,
                              this.state.nowFile,
                              this.state.nowFileID,
                              this.state.note
                            )
                          }
                        >
                          Add Version
                        </Button> */}
                      </Col>
                    </FormGroup>
                  </Form>
                </Modal>

                <Modal
                  as="div"
                  open={openComment}
                  onClose={this.onCloseCommentModal}
                  center
                >
                  <h2>
                    Add a Note for Version {this.state.selectedVersionForNote}
                  </h2>
                  <hr />
                  <Form as="div">
                    <FormGroup as="div">
                      <Input
                        type="textarea"
                        name="addCommentText"
                        placeholder="Add a note for this version..."
                        cols="500"
                        rows="10"
                        onChange={this.handleChangeComment}
                      />
                    </FormGroup>

                    <FormGroup as="div">
                      <Col as="div" className="text-right">
                        <Button
                          name="submitbtn"
                          className="btn btn-primary"
                          onClick={this.addNote}
                        >
                          Add Note
                        </Button>
                      </Col>
                    </FormGroup>
                  </Form>
                </Modal>
              </div>
            </div>
          ))}

          <Col as="div" className="text-right">
            {/* <Button variant="secondary" onClick={this.onOpenModal} className>
              Add Version
            </Button> */}
          </Col>

          <VersionHistoryNotes
            createdBy={this.state.nowFileCreatedBy}
            selectedVersion={this.state.selectedVersion}
            commentTime={this.state.fetchComment}
            comment={this.state.fetchComment}
          />
        </div>
      );
    }
  }
}

export default versionHistory;
