import React, { Component } from "react";
import axios from "axios";
import MyApp from './pdfview';
import { usePdf } from '@mikecousins/react-pdf';
import Pdf from '@mikecousins/react-pdf';

export default class Placeholder extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      tempx: null,
      tempy: null,
      pdfx: null,
      pdfy: null,
      sign: false,
      sx: null,
      sy: null,
      link: "Please put a placeholder on the file and click sign!",
      file: null,
      totalPages: null,

      //Credentials
      initFile: process.env.REACT_APP_IPFS_FILE + "ipfs/" + this.props.hash,
      // initFile: 'https://edocu.mesansolutions.co.in/'+ "ipfs/" + this.props.hash,
      // initFile: 'http://localhost:8080/'+ "ipfs/" + this.props.hash,
      pageNo: 1,
      selectedPage: null,
      canvas: [],
      pageWithPlaceholderLocation: [],
      full_name: "Aakash Varma",
      batchPage2: false
    };

    
  }

  nextPage = (event) => {
    this.setState({pageNo: this.state.pageNo + 1})
    console.log(this.state.pageNo);
    // this.setState({selectedPage: this})
    // return this.state.pageNo
    console.log(this.state.pageWithPlaceholderLocation)
    const canvas = this.refs.stamp_canvas;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(
      0,
      0,
      document.getElementById("pdff").children[0].clientWidth,
      this.refs.stamp_canvas.clientHeight
    );
    
  }

  prevPage = (event) => {
    this.setState({pageNo: this.state.pageNo - 1 })
    console.log(this.state.pageWithPlaceholderLocation)
    const canvas = this.refs.stamp_canvas;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(
      0,
      0,
      document.getElementById("pdff").children[0].clientWidth,
      this.refs.stamp_canvas.clientHeight
    );
    // console.log(this.state.pageNo);
  }

  componentDidMount = () => {
    this.refs.mycanvas.height = 550;
    this.refs.mycanvas.width = 450;
    this.refs.stamp_canvas.height = 550;
    this.refs.stamp_canvas.width = 450;

  };

  canvasPlaceRender = (e) => {
    //console.log(this.refs.pdf_canvas.clientHeight +"x"+ this.refs.pdf_canvas.clientWidth)
    //console.log(this.refs.mycan.clientHeight)

    // console.log('Canvas PLaceRender Start')

    // console.log(e)

    // console.log('Canvas PLaceRender End')
    const canvas = this.refs.mycanvas;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(this.state.tempx, this.state.tempy, 70, 30);
    ctx.fillStyle = "rgba(0, 0, 255, 0.5)";
    ctx.fillRect(
      e.nativeEvent.offsetX - 35,
      e.nativeEvent.offsetY - 15,
      70,
      30
    );
    this.setState({
      tempx: e.nativeEvent.offsetX - 35,
      tempy: e.nativeEvent.offsetY - 15,
    });
  };

  getDefn = (e) => {
    console.log(
      "X:Axis ---------- " +
        document.getElementById("pdff").children[0].height +
        " Y:Axis ---------- " +
        document.getElementById("pdff").children[0].width
    );
    this.refs.mycanvas.height =
      document.getElementById("pdff").children[0].clientHeight;
    this.refs.mycanvas.width =
      document.getElementById("pdff").children[0].clientWidth;
    this.refs.stamp_canvas.height =
      document.getElementById("pdff").children[0].clientHeight;
    this.refs.stamp_canvas.width =
      document.getElementById("pdff").children[0].clientWidth;
    this.setState({
      sign: true,
    });
  };

  // handleFileChange = (e) => {
  //   this.setState({
  //     file :e.target.files[0],
  //     //initFile: e.target.files[0]
  //   }, ()=>{console.log(this.state.file)});
  // }

  taskCom = (e) => {
    console.log(e.target.offsetX);
  };
  onSubmit = () => {
    let sendy =
      (297 / document.getElementById("pdff").children[0].height) *
      this.state.sy;
    let sendx =
      (210 / document.getElementById("pdff").children[0].width) * this.state.sx;

    console.log(sendx + " " + sendy);

    let formData = new FormData();
    formData.append("x-cord", sendx);
    formData.append("y-cord", sendy);
    formData.append("full_name", this.state.full_name);
    formData.append("page_no", this.state.pageNo);
    formData.append("ip_url", this.state.initFile);

    axios({
      method: "post",
      url: "http://localhost/sign_api/signapi.php",
      data: formData,
      headers: { Authorization: "123a" },
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then((response) => {
        //handle success
        this.setState({
          link: response.data.signed_file_url,
        });
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });

    //let sendx = this.state.sx / 0.492;
    //let sendy = this.state.sy / 0.343;
  };

  onNextPage = () => {
    if (this.state.pageNo === this.state.totalPages) {
      this.setState(
        {
          pageNo: 1,
        },
        () => console.log("Current Page : " + this.state.pageNo)
      );
    } else {
      this.setState(
        {
          pageNo: this.state.pageNo + 1,
        },
        () => console.log("Current Page : " + this.state.pageNo)
      );
    }
  };

  onPrevPage = () => {
    if (this.state.pageNo === 1) {
      this.setState(
        {
          pageNo: this.state.totalPages,
        },
        () => console.log("Current Page : " + this.state.pageNo)
      );
    } else {
      this.setState(
        {
          pageNo: this.state.pageNo - 1,
        },
        () => console.log("Current Page : " + this.state.pageNo)
      );
    }
  };


// prePlacholder = (e, pageNo) => {
//   const canvas = this.refs.stamp_canvas;
//   const ctx = canvas.getContext("2d");
//   ctx.clearRect(
//     0,
//     0,
//     document.getElementById("pdff").children[0].clientWidth,
//     this.refs.stamp_canvas.clientHeight
//   );
//   var img = new Image();
//   img.src = "sign.jpg";
//   ctx.font = "15px Georgia";
//   ctx.fillStyle = "red";
//   ctx.border = "1px solid red";
//   ctx.fillText(
//     "Sign Here",
//     // if(pageNo === this.state.pageNo) {
//     //  e.nativeEvent.offsetX - 30,

//     // }
//     e.nativeEvent.offsetX - 30,
//     e.nativeEvent.offsetY + 5
//   );

// }



  stamp = async (e) => {
    //console.log("stamp")
    let ax = e.nativeEvent.offsetX - 30;
    let ay = e.nativeEvent.offsetY + 5;
    const canvas = this.refs.stamp_canvas;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(
      0,
      0,
      document.getElementById("pdff").children[0].clientWidth,
      this.refs.stamp_canvas.clientHeight
    );
    var img = new Image();
    img.src = "sign.jpg";
    ctx.font = "15px Georgia";
    ctx.fillStyle = "red";
    ctx.border = "1px solid red";
    ctx.fillText(
      "Sign Here",
      e.nativeEvent.offsetX - 30,
      e.nativeEvent.offsetY + 5
    );



    this.setState({  
      sx:
      (210 / document.getElementById("pdff").children[0].clientWidth) *
      (e.nativeEvent.offsetX - 35),
      sy:
      (297 / document.getElementById("pdff").children[0].clientHeight) *
      (e.nativeEvent.offsetY - 15)
    });

    // console.log('array', this.state.pageWithPlaceholderLocation);
        const pageWithLocation = { x_cord: this.state.sx, y_cord: this.state.sy, actualX: ax, actualY: ay, pageNo: this.state.pageNo }
        const data = this.state.pageWithPlaceholderLocation.some(key => key.pageNo === pageWithLocation.pageNo);
        if(data) {
          console.log('update')

          const keys = this.state.pageWithPlaceholderLocation.findIndex(_item => _item.pageNo === pageWithLocation.pageNo)
          //  Retrieve Key if value exits
          let location = [...this.state.pageWithPlaceholderLocation]
          location.splice(keys,1,pageWithLocation);
          // this.setState({pageWithPlaceholderLocation: location})

          this.setState({
            pageWithPlaceholderLocation: location,
            sx:
            (210 / document.getElementById("pdff").children[0].clientWidth) *
            (e.nativeEvent.offsetX - 35),
            sy:
            (297 / document.getElementById("pdff").children[0].clientHeight) *
            (e.nativeEvent.offsetY - 15)}, () => {
                        this.props.triggerselectsigner(
                        this.state.sx,
                        this.state.sy,
                        this.state.pageNo,
                        ax,
                        ay,
                        this.state.pageWithPlaceholderLocation
                );

                console.log('array', this.state.pageWithPlaceholderLocation);
            })
          console.log(keys);

        } else {
          console.log('new')
          let location = [...this.state.pageWithPlaceholderLocation]
            location.push(pageWithLocation)
            console.log(location);
            this.setState({
              pageWithPlaceholderLocation: location,  
              sx:
              (210 / document.getElementById("pdff").children[0].clientWidth) *
              (e.nativeEvent.offsetX - 35),
              sy:
              (297 / document.getElementById("pdff").children[0].clientHeight) *
              (e.nativeEvent.offsetY - 15)}, () => {
                          this.props.triggerselectsigner(
                          this.state.sx,
                          this.state.sy,
                          this.state.pageNo,
                          ax,
                          ay,
                          this.state.pageWithPlaceholderLocation
                  );
                  console.log('array', this.state.pageWithPlaceholderLocation);
              })

        }

       console.log(data);

    // this.setState({
    //   tempxs: e.nativeEvent.offsetX - 35,
    //   tempys: e.nativeEvent.offsetY - 15,
    // });



//  this.props.triggerselectsigner(
//           this.state.sx,
//           this.state.sy,
//           this.state.pageNo,
//           ax,
//           ay,
//           this.state.pageWithPlaceholderLocation
//         );


  }


  getNumber(e) {
    console.log(e)
    const totPage = e;
    // this.setState({totalPages: totPage})
  }

  componentDidUpdate() {
    // document.title = `You clicked ${this.state.pageNo} times`;
  }

  setTotalPage(count) {
    console.log('total pages')
    console.log(count.numPages);
    this.setState({totalPages: count.numPages});
  }
  // onDocumentComplete = () =>{
  //   this.setState({
  //     pageNo:1
  //   },()=>console.log(this.state.pageNo))
  // }
  render() {
    // let pagination = null;

    // if (this.state.pages) {
    //   pagination = this.renderPagination();
    // }

    const enabled = this.props.selectedsigner.length > 0;
    // const enablesign =  this.state.sign===true
    return (
      <div>
        <canvas
          style={{ border: "1px solid black", position: "absolute" }}
          ref="stamp_canvas"
          onClick={(e) => this.stamp(e)}
          hidden={!this.state.sign}
        ></canvas>
        <canvas
          style={{ border: "1px solid black", position: "absolute" }}
          ref="mycanvas"
          hidden={!this.state.sign}
          onMouseMove={(e) => this.canvasPlaceRender(e)}
          onClick={(e) => this.stamp(e)}
        ></canvas>

        <div ref="pdf_canvas" id="pdff">

          <Pdf file={this.state.initFile} page={this.state.pageNo} onDocumentLoadSuccess={(e) => this.setTotalPage(e)}>
      {({ pdfDocument, pdfPage, canvas }) => (
        // console.log()
        <>
        {!pdfDocument && <span>Loading...</span>}
        {canvas}
        {Boolean(pdfDocument && pdfDocument.numPages) && <div onLoad={(e) => {this.setTotalPage(pdfDocument.numPages)}}> </div>}
        </>
      )}
    </Pdf>
        </div>

        <button
          className="btn btn-outline"
          onClick={(e) => this.getDefn(e)}
          style={{ margin: 10 }}
          disabled={!enabled}
        >
          Placeholder
        </button>
        {/* <button onClick={(e)=>this.onSubmit(e)}style={{margin: 10}} disabled={!enablesign}>Sign</button> */}
        <button disabled={this.state.pageNo === 1} className="btn btn-outline" onClick={(e) => this.prevPage(e)} style={{ margin: 10 }}>
          {"<"}
        </button>
        {this.state.pageNo}
        <button  disabled={this.state.pageNo > this.state.totalPages - 1}  className="btn btn-outline" onClick={(e) => this.nextPage(e)} style={{ margin: 10 }}>
          {">"}
        </button>
        {/* <input type = "file" onChange={this.handleFileChange}></input> */}
        {/* <h2><a href={this.state.link}>{this.state.link}</a></h2> */}
      </div>
    );
  }
}
