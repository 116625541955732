import React, { Component } from 'react'
import Iframe from 'react-iframe'
import { Button } from 'react-bootstrap';
import Header from './components/Header'
import { Card, Container, Col, Row } from 'react-bootstrap';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';
// import ipfsClient from 'ipfs-http-client';
import { ToastContainer, toast } from 'react-toastify';
import Image from 'react-bootstrap/Image'
import axios from 'axios';

// var ipfs = ipfsClient({ host: 'localhost', port: '5001', protocol: 'http' })

class SignerDocument extends Component {
    constructor(props) {
        super(props)

        this.state = {
            urlcontainer: null,
            fields: {},
            
            errors: {},
            x_cord: null,
            y_cord: null,
            page_no: null,
            fullname: 'default name',
            file: '[]',
            fileObj: [],
            uploadButton: true,
            firstName: [],
            lastName: [],
            signature: []

        }
        // this.responsefromphpAPI=this.responsefromphpAPI.bind(this);
        this.checkIfSignatureIsUploaded();
        
    }

    componentDidMount=async()=> {
        
    

        let url = new URL(process.env.REACT_APP_FRONTEND+'signingdocument?url=mycontent&x_cord=x&y_cord=y&full_name=name&page_no=page&id=id');

        function getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        }
        // Give the parameter a variable name
        var dynamicContent;
        let x_cord = getParameterByName('x_cord');
        let y_cord = getParameterByName('y_cord');
        let fullname = getParameterByName('full_name');
        let page_no = getParameterByName('page_no');
        let id = getParameterByName('id');
        let token = getParameterByName('jwt');
        sessionStorage.setItem('token', token);
        // console.log(getParameterByName('email'))

        // await fetch(`${process.env.REACT_APP_API_URL}geturl?id=`+id)
        // .then(response => response.json())
        // .then(response => this.setState({ checklatesturl: response.data[0].hash}))
        // .catch(err => console.error(err))

        axios.get(process.env.REACT_APP_API_URL + '/getDocument?id=' + id).then(fileData => {
            console.log(fileData.data.file.hash);
            dynamicContent = fileData.data.file.hash;
            if(this.state.checklatesturl === undefined){
                this.setState({
                    checklatesturl:dynamicContent
                })
              }
              
        
                this.setState({
                    urlcontainer: process.env.REACT_APP_IPFS_FILE + 'ipfs/' + dynamicContent,
                    x_cord: x_cord,
                    y_cord: y_cord,
                    fullname: fullname,
                    page_no: page_no,
                    hash: dynamicContent,
                    id: id,
                    latesturl: process.env.REACT_APP_API_URL +this.state.checklatesturl
                })
        }).catch(err => {
            console.log(err);
        });


        axios.get(`${process.env.REACT_APP_API_URL}getUserDetails`)
        .then(resp => {
            console.log(resp);
            this.setState({
                firstName: resp.data.firstName,
                lastName: resp.data.lastName,
                email: resp.data.email,
                file: resp.data.signature
            })
        }).catch(err => {
            console.log(err);
        }) 

    //    await this.FirstFunction(dynamicContent, id)

     

    //     let name = fullname.trim(' ').split(' ');
    //   await  fetch(`${process.env.REACT_APP_API_URL}accessEmailFromname?firstname=` + name[0] + '&lastname=' + name[1])
    //         .then(response => response.json())
    //         .then(response => this.setState({ responseforemail: response.data[0].email },()=>console.log(this.state.responseforemail)))
    //         .catch(err => console.error(err))


    }

    FirstFunction = async (hash, id) => {
        await fetch(`${process.env.REACT_APP_API_URL}selectowaFileHash?id=` + id)
            .then(response => response.json())
            .then(response => this.setState({ responseFromIWFileHash: response.data[0].versioncount }))
            .catch(err => console.error(err))

        await fetch(`${process.env.REACT_APP_API_URL}selectowaFileHashforFileName?id=` + id)
            .then(response => response.json())
            .then(response => this.setState({
                filename: response.data[0].fileName,
                category: response.data[0].category

            }))
            .catch(err => console.error(err))
    }



checkIfSignatureIsUploaded = () => {
    axios.get(`${process.env.REACT_APP_API_URL}checkSignatureUrl?token=` + this.state.email).then(res => {
        // const data = res.json();
        console.log(res);
        // console.log(data);
        if(res.data !==null) {

        
        if (res.data[0].signature !== null ) {
            this.setState({ uploadButton: false })
            this.setState({ file: res.data[0].signature });
            // this.state.file = JSON.stringify(res.signature);
            this.setState({ firstName: res.data[0].first_name, lastName: res.data[0].last_name });
        } else if (res.data !== null) {
            if (res.data[0].signature !== null) {
                this.setState({ uploadButton: false })
            } else {
                this.setState({ uploadButton: true })
            }
            this.setState({ firstName: res.data[0].first_name, lastName: res.data[0].last_name });
        } else {
            if (res.data[0].signature !== null) {
                this.setState({ uploadButton: false })
            } else {
                this.setState({ uploadButton: true })
            }

            console.log('No Data found');
        }
    }

    }, error => {
        console.log(error);
    })
}

uploadSignature = () => {
    

    var bodyFormData = new FormData();
    bodyFormData.append('image', this.state.fileObj);
    axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}uploadSignature`,
        data: bodyFormData,
        params: {
            token: this.state.email
        },
        headers: { "Content-Type": "multipart/form-data" },
    })
        .then(function (response) {
            //handle success
            toast.success('Successfully Uploaded')
            console.log(response);
        })
        .catch(function (response) {
            //handle error
            toast.error('Error while Uploaded')
            console.log(response);
        });
}

// By Yash T
fileUpload = event => {

    // Update the state
    console.log(event.target.files);
    this.setState({ file: URL.createObjectURL(event.target.files[0]), fileObj: event.target.files[0] });
    this.setState({ uploadButton: true });
};

signDocument = () =>  {
        
    axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}attachSignatureToFile`,
        data: {},
        params: {
            id: this.state.id,
            // token: sessionStorage.getItem('token')
        },
        headers: { "Content-Type": "multipart/form-data" },
    })
        .then(function (response) {
            if(response.data.fileAddress) {
                window.open(response.data.fileAddress.fileUrl);
            }
            console.log(response);
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
}


    render() {
        return (
            <div className="App">
            <ToastContainer />
                {/* <Header /> */}
                <Container as="div" fluid className='mt-5'>
                    <div className="app-layout pattern">
                        <Card as="div" className='mt-2'>
                            <Card.Body as="div">
                                <Card.Title as="div">
                                    <Row as="div">
                                        <Col as="div" className="page-title">Proceed To SignA Document... </Col>

                                    </Row>
                                </Card.Title>
                                <Card.Text as="div">
                                    <Row as="div">
                                        <Col as="div" sm={8}>
                                            <React.Fragment>
                                                <Iframe url={this.state.urlcontainer+'#toolbar=0'}
                                                    width="80%"
                                                    height="700 px"
                                                    id="myId"
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative" />

                                            </React.Fragment>
                                        </Col>
                                        <Col sm={4} as="div">

                                            <ListGroup as="div">
                                                <h5 style={{ fontFamily: "monospace" }}>Check Details:</h5>
                                                <h5><strong>Name</strong></h5>
                                                <ListGroupItem>{this.state.firstName} {this.state.lastName}</ListGroupItem>

                                                 <input type="file" onChange={this.fileUpload} />

                                                <div className="">
                                                    <Image width="150px" src={this.state.file} fluid />
                                                </div>
                                                  {this.state.uploadButton && <Button variant="primary" type="submit" style={{ width: "100%", height: "43px" }} className="button1" onClick={this.uploadSignature} >
                                                    <span style={{ fontSize: "14px", fontStyle: "bold" }} >Upload Signature</span>
                                                </Button>}
                                                <Button variant="primary" type="submit" style={{ width: "100%", height: "43px" }} className="button1" onClick={this.signDocument} >
                                                    <span style={{ fontSize: "14px", fontStyle: "bold" }} >Sign A Document</span>
                                                </Button>
                                            </ListGroup>




                                        </Col>

                                    </Row>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </Container>

            </div>
        )
    }
}

export default withRouter(SignerDocument)