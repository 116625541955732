import React, { Component } from 'react'
import {Redirect} from 'react-router-dom'

export default class Logout extends Component {
    constructor(props){
        super(props)
       const token= sessionStorage.removeItem("token")
        let loggedIn=true
      if(token == null){
        loggedIn=false
    }
    this.state = {
        loggedIn
      }
    }
    render() {
        if(this.state.loggedIn ===false){
            return <Redirect to="/login"/>
        }
        return (
            <div>
                <h1>you have logout</h1>
            </div>
        )
    }
}
