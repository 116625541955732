import React,{Component} from 'react';
import './App.scss';
import Header from './components/Header';
// import VersionHistoryNotes from './VersionHistoryNotes';
import { Button, Card, Container, Col, Row } from 'react-bootstrap';
import ipfsClient from 'ipfs-http-client'
import CheckMail from './CheckMail';
import VersionHistoryMail from './VersionHistoryMail';

export default class DocumentsLandingMail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldVal: "",
      selectedFile:"",
      selectedId:""
    }
    this.changeFile = this.changeFile.bind(this);
  }

  changeFile = (newFileName,newId) =>{
    this.setState({
      selectedFile:newFileName,
      selectedId:newId
      
    },
    () => console.log(this.state.selectedFile,this.state.selectedId));  
  }




 
  render() {
    return (
      
      <div className="App">
         
      <Header />
      <Container fluid>
        <div className="app-layout pattern">
          <Card>
            <Card.Body>
              <Card.Title>
                <Row>
                  <Col className="page-title">Document For Sign</Col>
                  <Col className="text-right">
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text>
                <Row>
                  <Col sm={7}>
                    <div className="left-panel data-content">
                      {/* <TableData triggerChangeFile={this.changeFile} /> */}
                      <CheckMail triggerChangeFile={this.changeFile}/>
                    </div>
                  </Col>
                  <Col sm={5} className="right-panel data-content">
                    {/* <VersionHistory selectedFile={this.state.selectedFile} selectedId={this.state.selectedId}/> */}
                <VersionHistoryMail selectedFile={this.state.selectedFile} selectedId={this.state.selectedId}/>
                    {/* <VersionHistoryNotes /> */}
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
    )
  }
}



