import React, { Component } from 'react';
import './App.scss';
import Header from './components/Header';
import { Button, Card, Container, Col, Row } from 'react-bootstrap';
import ipfsClient from 'ipfs-http-client'
import Dashboard from './Dashboard';
import Axios from 'axios';

export default class DashboardLanding extends Component {
    constructor(props) {
        super(props);

        this.state = {
            signedCount: [],
            awaitingCount:[],
            alldocuments:[],
            awaitingOthersCount:[]
        }

        this.getDocuments = this.getDocuments.bind(this);
    }

    componentDidMount() {

        const token=sessionStorage.getItem("userId")
        console.log(token);
        this.getDocuments()
        // fetch(`${process.env.REACT_APP_API_URL}CountSignedDoc`)
        //     .then(response => response.json())
        //     .then(response => this.setState({ signedCount: response.data }))
        //     .catch(err => console.error(err))

        // fetch(`${process.env.REACT_APP_API_URL}CountAwaitingDoc?token=`+token)
        //     .then(response => response.json())
        //     .then(response => this.setState({ awaitingCount: response.data }))
        //     .catch(err => console.error(err))

            
    }




    getDocuments() {
        const token=sessionStorage.getItem("userId")
        Axios.get(process.env.REACT_APP_API_URL+ 'getCountOfDocuments?token='+token)
            .then(response => this.setState({ 
                alldocuments: response.data.fileCreatedCount,
                awaitingCount: response.data.awaitingCount,
                awaitingOthersCount: response.data.awaitingOthersCount
             }))
            .catch(err => console.error(err))
    }


    render() {
        const { signedCount } = this.state;
        const {awaitingCount} = this.state;
        const {alldocuments} = this.state;
        const {awaitingOthersCount} = this.state;
        return (

            <div className="App">

                <Header />
                <Container as="div" fluid>
                    <div className="app-layout pattern">
                        <Card as="div">
                            <Card.Body as="div">
                                <Card.Title as="div">
                                    <Row as="div">
                                        {/* <Col className="page-title">All Documents (38)</Col> */}
                                        
                                    </Row >
                                </Card.Title>
                                <Card.Text as="div">
                                    <Row as="div">
                                        <Col as="div" sm={12}>
                                            <Dashboard signedCount={signedCount} awaitingCount={awaitingCount} alldocuments={alldocuments} awaitingOthersCount={awaitingOthersCount} update={this.getDocuments}/>
                                        </Col>

                                    </Row>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </Container>
            </div>
        )
    }
}



