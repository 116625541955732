import React, { Component } from 'react'
import {Image, Navbar, Nav} from 'react-bootstrap';
import logo from '../../src/assets/images/logo.png';
import '../../src/assets/scss/_header.scss';

export default class DocumentsLanding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: sessionStorage.getItem('token')
    };

    this.handleScroll = this.handleScroll.bind(this);
}

handleScroll() {
    this.setState({scroll: window.scrollY});
}

componentDidMount() {
    const el = document.querySelector('nav');
    this.setState({top: el.offsetTop, height: el.offsetHeight});
    window.addEventListener('scroll', this.handleScroll);
}

componentDidUpdate() {
    this.state.scroll > this.state.top ? 
        document.body.style.paddingTop = `${this.state.height}px` :
        document.body.style.paddingTop = 0;
}

  
  render() {
    return (
      <div className="header">
      <Navbar id="navbar" expand="lg">
        <Navbar.Brand>
          <Image className="app-logo__image" src={logo} alt="Versa Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            // activeKey="/documents"
            // onSelect={selectedKey => alert(`selected ${selectedKey}`)}
            className={this.state.scroll > this.state.top ? "fixed-nav" : ""}>
            {/* <Nav.Link  href="/home">Home</Nav.Link> */}
            <Nav.Link  href="/documents">Documents</Nav.Link>
            <Nav.Link  href="/dash">Dashboard</Nav.Link>
            <Nav.Link  href="/category">Categories</Nav.Link>
            <Nav.Link  href="/users">All Users</Nav.Link>
            <Nav.Link  href="/template">Template</Nav.Link>
            <Nav.Link  href="/logout">Logout</Nav.Link>          
            
          </Nav>         
        </Navbar.Collapse>
        <div className="float-right pr-5">
        <span>Logged in as:</span> &nbsp; &nbsp; {sessionStorage.getItem('user')}
      </div>
      </Navbar>
      
    </div>
    )
  }
}

