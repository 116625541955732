import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Se } from "reactstrap";
import Modal from "react-responsive-modal";
import Header from "./components/Header";
import { Card, Container, Col, Row } from "react-bootstrap";
import signature from "./assets/html/images/signature.png";
import observer from "./assets/images/observer.jpeg";
import { Redirect } from "react-router-dom";

// import reviewer from './assets/images/reviewer.png'
import { ToastContainer, toast } from 'react-toastify';    
import 'react-toastify/dist/ReactToastify.css';
import ecommmerce1 from "./assets/html/images/ecommerce/1.png";
import "./assets/html/css/ecommerce.css";
import { withRouter } from "react-router-dom";
import Picky from "react-picky";
import "react-picky/dist/picky.css";

import { ListGroup, ListGroupItem } from "reactstrap";
import "./index.scss";
import TableDatamail from "./CheckMail";

// import './assets/html/css/main.css'

import "./assets/html/css/color_skins.css";
import { async } from "q";
import { element } from "prop-types";
import Axios from "axios";

let sampleArray = [];
let userunionguest = [];
let signerarrayforHL = [];
let observerarrayforHL = [];
let reviewerarrayforHL = [];
let MailArray = [];
class Signers extends Component {
  constructor(props) {
    super(props);
    if (this.props.location.state === undefined) {
      this.props.history.push({
        pathname: "/documents",
      });
      window.location.reload();
    }

    this.state = {
      open: false,
      openSigner: false,
      openObserver: false,
      name: "",
      email: "",
      Lastname: "",
      signers: [],
      observer: [],
      list: [],
      newStateArray: [],
      dropdownOpen: false,
      flag1: false,
      flag2: false,
      flag3: false,
      flag4: false,
      signerDB: [],
      signeraddinDB: [],
      selectSignerfromDB: [],
      value: null,
      value1: null,
      arrayValueofSigner: [],
      arrayValueSigner: [],
      dropdownlistforsigner: [],
      resObj: null,

      arrayvalueforobserver: [],
      arrayValueofReviewer: [],
      list1: [],
      SignerAddInModal: [],
      selectedShips: [],
      checkarray: ["123", "4545"],
      isChecked: false,
      isnotChecked: true,
      checkedmail: "",
      checkedmailarray: [],
      mail: "",
      AccessFileHashDb: [],
      selectSignerFromDB: [],
      observeraddinDB: [],
      ObserverAddInModal: [],
      selectObserverFromDB: [],
      checkMailForObserverAddInDB: [],
      checkMailForSignerAddInDB: [],
      deleteSamemailfromlist1: [],
      deleteSamemailfromlist: [],
      updateofobserver: "",
      updateofsameobserver: "",

      updateforsigner: "",
      updateforsamesigner: "",
      responseFromNewSigner: "",
      accesssystemuser: [],
      accessallemailguestsigner: [],
      accessuserunionguest: [],
      updateforReviewer: "",
      openReviewer: "",
      selectReviewerFromDB: [],
      dropdownlistforReviewer: [],
      accesssystemuserforreviewer: [],
      Allmail: [],
      currentmailid: "",
      findnameusingemail: [],
      apikey: "zBBsffFEHrHmZ772cVE8LClg2hNeld",
      findnamestate: [],
      accessobserverdropdown: [],
      dropdownforobser: [],
      loggedIn: true,
      counter: 120,
      selectedUserId: [],
      selectedObserverId: [],
      selectedReviewerId: [],
      type: []
    };
    this.hyperledger_Function = this.hyperledger_Function.bind(this);
    this.onOpenModalForObserver = this.onOpenModalForObserver.bind(this);
    this.updateAllSignerInDropDown = this.updateAllSignerInDropDown.bind(this);
    this.updateAllObserverInDropDown =
      this.updateAllObserverInDropDown.bind(this);
    this.combo_Flag = this.combo_Flag.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitForObserver = this.handleSubmitForObserver.bind(this);
    this.AddItemsToArray = this.AddItemsToArray.bind(this);
    this.toggle = this.toggle.bind(this);
    this.selectMultipleOption = this.selectMultipleOption.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.selectMultipleOptionForObserver =
      this.selectMultipleOptionForObserver.bind(this);
    this.selectMultipleOptionForReviewer =
      this.selectMultipleOptionForReviewer.bind(this);
    this.callNecessaryAPI = this.callNecessaryAPI.bind(this);
  }

  selectOption(value) {
    console.log("Vals", value);
    this.setState({ value });
  }
  selectMultipleOption(value) {
    console.count("onChange");
    console.log("Val", value);
    this.setState({
      arrayValueofSigner: value,
      selectedUserId: value,
    });
  }
  selectMultipleOptionForReviewer(value) {
    console.count("onChange");
    console.log("Val", value);
    this.setState({
      arrayValueofReviewer: value,
    });
  }
  selectMultipleOptionForObserver(value) {
    console.count("onChange");
    console.log("Val", value);
    this.setState({
      arrayvalueforobserver: value,
      selectedObserverId: value,
    });
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    console.log(this.state)
  };
  onOpenModal = () => {
    this.setState({ open: true });
  };
  onOpenModalForReviewer = () => {
    this.setState({ openReviewer: true });
  };

  onOpenModalForObserver = () => {
    this.setState({ openObserver: true });
  };

  onCloseModal = () => {
    this.setState({
      open: false,
    });
  };

  onCloseModalForReviewer = () => {
    this.setState({
      openReviewer: false,
    });
  };

  onCloseModalForObserver = () => {
    this.setState({ openObserver: false });
  };

  AddItemsToArray = () => {
    //Adding Items To Array.
    sampleArray.push(this.state.email.toString());

    this.setState(
      (prevState) => ({
        list: [...prevState.list, this.state.email],
        // list1: [...prevState.list1, this.state.email]
      }),
      () => this.InsertSignertoDB(this.state.list)
    );
  };
  AddItemsToArrayForObserver = () => {
    this.setState(
      (prevState) => ({
        list1: [...prevState.list1, this.state.email],
      }),
      () => this.InsertObservertoDB(this.state.list1, this.state.email)
    );
  };

  handleSubmitForReviewer = (e) => {
    e.preventDefault();
    const { name, email } = this.state;

    this.state.accessuserunionguest.map((element) =>
      userunionguest.push(element.email)
    );
    var resultofunion = userunionguest.includes(this.state.email);
    console.log(resultofunion);
    if (resultofunion) {
      console.log("email found");
      console.log("same mail" + this.state.email);
      this.setState({
        updateforReviewer: "Hey!!! Email is already Registered.",
      });
      alert("Hey!!! Email is already Registered.");
      //           window.location.reload();
    } else {
      this.setState({ updateforReviewer: "" });
      Axios.post(process.env.REACT_APP_API_URL + "createUser")
        .then((response) =>
          this.setState({ responseFromNewReviewer: response.data })
        )
        .catch((err) => console.error(err));

      window.location.reload();
    }
    this.onCloseModalForReviewer();
  };

  handleSubmit(e) {
    e.preventDefault();
    const { name, email } = this.state;

    // //***** signer mail functionality */
    // fetch('http://localhost:4000/signerMail?name=' + name + "&email=" + email)
    //     .then(response => response.json())
    //     .then(response => this.setState({ signers: response.data }))
    //     .catch(err => console.error(err))

    this.state.accessuserunionguest.map((element) =>
      userunionguest.push(element.email)
    );
    var resultofunion = userunionguest.includes(this.state.email);
    console.log(resultofunion);
    if (resultofunion) {
      console.log("email found");
      console.log("same mail" + this.state.email);
      this.setState({ updateforsigner: "Hey!!! Email is already Registered." });
      alert("Hey!!! Email is already Registered.");
      //           window.location.reload();
    } else {
      this.setState({ updateforsigner: "" });
      console.log("email not found");
      // fetch(`${process.env.REACT_APP_API_URL}GuestSigner`, {
      //     method: 'post',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({
      //         "email": this.state.email,
      //         "role": "Guest Signer",
      //         "firstname": this.state.name,
      //         "lastname": this.state.Lastname
      //     }),
      // })
      //     .then(response => response.json())

      const data = {
        companyId: sessionStorage.getItem("companyId"),
        firstName: this.state.name,
        lastName: this.state.Lastname,
        email: this.state.email,
        type: this.state.type,
      };

      Axios.post(process.env.REACT_APP_API_URL + "createUser", data)
        .then((response) =>
          this.setState({ responseFromNewReviewer: response.data })
        )
        .catch((err) => console.error(err));
      // .then(response => this.setState({ responseFromNewSigner: response.data }))
      // .catch(err => console.error(err));

      // await window.location.reload()
    }
    // this.setState(prevState => ({
    //     dropdownlistforsigner: [...prevState.dropdownlistforsigner, this.state.email]
    //     // list1: [...prevState.list1, this.state.email]

    // }), () => this.InsertSignertoDB(this.state.dropdownlistforsigner, this.state.email))        // var button = document.getElementById('add_signers');

    // this.InsertSignertoDB(email)
    // window.location.reload()

    this.callNecessaryAPI();
    this.onCloseModal();
  }

  handleSubmitForObserver(e) {
    e.preventDefault();
    const { name, email } = this.state;

    fetch(
      `${process.env.REACT_APP_API_URL}observerMail?name=` +
        name +
        "&email=" +
        email
    )
      .then((response) => response.json())
      .then((response) => this.setState({ observer: response.data }))
      .catch((err) => console.error(err));

    this.state.accessuserunionguest.map((element) =>
      userunionguest.push(element.email)
    );
    var resultofunion = userunionguest.includes(this.state.email);
    console.log(resultofunion);
    if (resultofunion) {
      console.log("email found");
      console.log("same mail" + this.state.email);
      this.setState({
        updateofobserver: "Hey!!! Email is already Registered.",
      });
      alert("Hey!!! Email is already Registered.");
      //           window.location.reload();
    } else {
      // this.setState({updateforsigner:""})
      console.log("email not found");
      fetch(`${process.env.REACT_APP_API_URL}GuestSigner`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: this.state.email,
          role: "Guest Observer",
        }),
      })
        .then((response) => response.json())
        .then((response) =>
          this.setState({ responseFromNewObserver: response.data })
        )
        .catch((err) => console.error(err));

      window.location.reload();
    }

    // this.setState(prevState => ({
    //     list1: [...prevState.list1, this.state.email]
    // }), () => this.InsertObservertoDB(this.state.list1, this.state.email))

    // var button = document.getElementById('add_signers');
    // if (this.state.list.length > -1) {
    //     button.style.backgroundColor = "#FFA500"
    // }
    // if (this.state.list.length > 0) {
    //     button.style.backgroundColor = "#66ffcc"
    // }
    // this.InsertObservertoDB(email)

    this.onCloseModalForObserver();
  }
  combo_Flag = () => {
    if (
      (this.state.flag1 === false &&
        this.state.selectSignerFromDB.length > 0) ||
      this.state.selectObserverFromDB.length > 0 ||
      this.state.selectReviewerFromDB.length > 0
    ) {
      this.setState({
        flag1: false,
        flag2: true,
        flag3: false,
        flag4: false,
      });
    } else {
      alert("Please add a one signer at least");
    }
    if (this.state.flag2 === true) {
      this.setState({
        flag1: false,
        flag2: true,
        flag3: true,
        flag4: false,
      });
    }
    if (this.state.flag3 === true) {
      this.setState({
        flag1: false,
        flag2: true,
        flag3: true,
        flag4: true,
      });
    }
  };

  callNecessaryAPI() {
    //  + '&fileId=' + this.props.location.state.fileID
    Axios.get(
      `${process.env.REACT_APP_API_URL}getUser?company=true` +
        "&companyId=" +
        sessionStorage.getItem("companyId")
    )
      .then((response) => {
        this.setState({
          accesssystemuser: response.data,
          accessobserverdropdown: response.data,
          accesssystemuserforreviewer: response.data,
        });
        this.updateAllSignerInDropDown();
        this.updateAllObserverInDropDown();
        this.updateAllReviewerInDropDown();
      })
      .catch((err) => {
        alert(err);
      });

    Axios.get(
      process.env.REACT_APP_API_URL +
        "getDocumentDetails?fileId=" +
        this.props.location.state.fileID +
        "&type=Signer"
    )
      .then((data) => {
        console.log(data);
        this.setState({
          selectSignerFromDB: data.data.files,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get(
      process.env.REACT_APP_API_URL +
        "getDocumentDetails?fileId=" +
        this.props.location.state.fileID +
        "&type=Observer"
    )
      .then((data) => {
        console.log(data);
        this.setState({
          selectObserverFromDB: data.data.files,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get(
      process.env.REACT_APP_API_URL +
        "getDocumentDetails?fileId=" +
        this.props.location.state.fileID +
        "&type=Reviewer"
    )
      .then((data) => {
        console.log(data);
        this.setState({
          selectReviewerFromDB: data.data.files,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    //access from systemuser

    // this.updateAllSignerInDropDown();

    this.callNecessaryAPI();

    // fetch(`${process.env.REACT_APP_API_URL}getUser?company=true` + '&companyId=' + '4' )
    //     .then(response => response.json())
    //     .then(response => this.setState({
    //         accesssystemuser: response,
    //         accessobserverdropdown: response,
    //         accesssystemuserforreviewer: response
    //      }))
    //     .catch(err => console.error(err))

    // fetch(`${process.env.REACT_APP_API_URL}selectdataforobserverdropdown?id=` + this.props.location.state.fileID)
    //     .then(response => response.json())
    //     .then(response => this.setState({ accessobserverdropdown: response.data }, () => this.updateAllObserverInDropDown()))
    //     .catch(err => console.error(err))

    // fetch(`${process.env.REACT_APP_API_URL}selectdatafromSUGUSRRV?id=` + this.props.location.state.fileID)
    //     .then(response => response.json())
    //     .then(response => this.setState({ accesssystemuserforreviewer: response.data }, () => this.updateAllReviewerInDropDown()))
    //     .catch(err => console.error(err))

    // fetch(`${process.env.REACT_APP_API_URL}selectalldataforSystemUser`)
    //     .then(response => response.json())
    //     .then(response => this.setState({ accessallemailsystemuser: response.data }, () => console.log(this.state.accessallemailsystemuser)))
    //     .catch(err => console.error(err))

    // fetch(`${process.env.REACT_APP_API_URL}selectalldatafromguestsigner`)
    //     .then(response => response.json())
    //     .then(response => this.setState({ accessallemailguestsigner: response.data }, () => console.log(this.state.accessallemailguestsigner)))
    //     .catch(err => console.error(err))

    // fetch(`${process.env.REACT_APP_API_URL}selectuserunionguest`)
    //     .then(response => response.json())
    //     .then(response => this.setState({ accessuserunionguest: response.data }, () => console.log(this.state.accessuserunionguest)))
    //     .catch(err => console.error(err))

    // fetch(`${process.env.REACT_APP_API_URL}selectdataforsigner?id=` + this.props.location.state.fileID)
    //     .then(response => response.json())
    //     .then(response => this.setState({ AccessFileHashDb: response.data }))
    //     .catch(err => console.error(err))

    // fetch(`${process.env.REACT_APP_API_URL}selectsigner?id=` + this.props.location.state.fileID)
    //     .then(response => response.json())
    //     .then(response => this.setState({ selectSignerFromDB: response.data }))
    //     .catch(err => console.error(err))

    // fetch(`${process.env.REACT_APP_API_URL}selectobserver?id=` + this.props.location.state.fileID)
    //     .then(response => response.json())
    //     .then(response => this.setState({ selectObserverFromDB: response.data }, () => console.log(this.state.selectObserverFromDB)))
    //     .catch(err => console.error(err))

    // fetch(`${process.env.REACT_APP_API_URL}selectReviewer?id=` + this.props.location.state.fileID)
    //     .then(response => response.json())
    //     .then(response => this.setState({ selectReviewerFromDB: response.data }))
    //     .catch(err => console.error(err))

    // fetch('http://localhost:4000/selectsignerfromDB')
    //     .then(response => response.json())
    //     .then(response => this.setState({ SignerAddInModal: response.data }, () => this.updateAllSignerInDropDown()))
    //     .catch(err => console.error(err))

    // fetch('http://localhost:4000/selectObserverfromDB')
    //     .then(response => response.json())
    //     .then(response => this.setState({ ObserverAddInModal: response.data }, () => this.updateAllObserverInDropDown()))
    //     .catch(err => console.error(err))
  }

  updateAllSignerInDropDown = () => {
    // console.log(this.state.SignerAddInModal)

    if (this.state.dropdownlistforsigner === null) {
      alert("not null");
    } else {
      console.log(this.state.accesssystemuser);
      this.state.accesssystemuser.map((item) =>
        this.state.dropdownlistforsigner.push({
          id: item.id,
          email: item.email,
        })
      );
      // this.state.SignerAddInModal.map((item) => this.state.list1.push(item.mailID))
    }
  };

  updateAllReviewerInDropDown = () => {
    if (this.state.dropdownlistforReviewer === null) {
      alert("not null");
    } else {
      this.state.accesssystemuserforreviewer.map((item) =>
        this.state.dropdownlistforReviewer.push({
          id: item.id,
          email: item.email,
        })
      );
      // this.state.SignerAddInModal.map((item) => this.state.list1.push(item.mailID))
    }
  };
  updateAllObserverInDropDown = () => {
    if (this.state.dropdownforobser === null) {
      alert("not null");
    } else {
      console.log(this.state.accessobserverdropdown);

      this.state.accessobserverdropdown.map((item) =>
        this.state.dropdownforobser.push({ id: item.id, email: item.email })
      );
    }
  };

  assignUserTypeToFile = (type, id) => {
    //     console.log(this.props.location.state.fileID)
    //     let full_name;
    //   let y_cordinate;
    console.log("shsdhasjkda");
    console.log(id);
    // console.log(this.state.arrayValueofSigner);
    // const arraySigner = this.state.arrayValueSigner;
    if (type === "Signer") {
      this.state.arrayValueofSigner.forEach((element) => {
        fetch(process.env.REACT_APP_API_URL + "assignUserTypeToFile", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            // "email": element,
            // "id": this.props.location.state.fileID,
            userId: element.id,
            companyId: sessionStorage.getItem("companyId"),
            fileId: this.props.location.state.fileID,
            type: type,
            signed: false,
          }),
        })
          .then((response) => {
            console.log(response);
            response.json();

            toast.success('Successfully Added ' + type)
            this.callNecessaryAPI();
          })
          .catch((err) => console.error(err));

        // await fetch('http://localhost:4000/checkfornameusingemail?email=' + element)
        //     .then(response => response.json())
        //     .then(response => this.setState({ findnameusingemail: response.data }, async () => {
        //         await this.state.findnameusingemail.map((name) => {
        //             full_name = name.first_name + " " + name.last_name
        //         })
        //     }
        //     ))
        //     .catch(err => console.error(err))
        //console.log(element + " " + full_name)

        // this.setState((prevState, props) => ({
        //     counter: prevState.counter + 10
        // }),()=>  {
        //     y_cordinate=this.state.counter
        // });

        // console.log("below is file id")

        // console.log(this.props.location.state.fileID)

        //  await   fetch('http://localhost:4000/signeremail?email=' + element +'&id='+this.props.location.state.fileID+'&url=' + this.props.location.state.hash + '&full_name=' + full_name+'&y_cord='+y_cordinate)
        //         .then(response => response.json())
        //         .then(response => this.setState({ Signeremail: response.data },()=>console.log(response.data)))
        //         .catch(err => console.error(err))
      });
    } else if (type === "Observer") {
      this.state.arrayvalueforobserver.forEach((element) => {
        fetch(process.env.REACT_APP_API_URL + "assignUserTypeToFile", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            // "email": element,
            // "id": this.props.location.state.fileID,
            userId: element.id,
            companyId: sessionStorage.getItem("companyId"),
            fileId: this.props.location.state.fileID,
            type: type,
            signed: false,
          }),
        })
          .then((response) => {
            toast.success('Successfully Added ' + type)
            console.log(response);
            response.json();
            this.callNecessaryAPI();
          })
          .catch((err) => console.error(err));
      });
    } else {
      this.state.arrayValueofReviewer.forEach((element) => {
        fetch(process.env.REACT_APP_API_URL + "assignUserTypeToFile", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            // "email": element,
            // "id": this.props.location.state.fileID,
            userId: element.id,
            companyId: sessionStorage.getItem("companyId"),
            fileId: this.props.location.state.fileID,
            type: type,
            signed: false,
          }),
        })
          .then((response) => {
            toast.success('Successfully Added ' + type)
            console.log(response);
            response.json();
            this.callNecessaryAPI();
          })
          .catch((err) => console.error(err));
      });
    }
    //   await window.location.reload()
  };

  // CheckMailForObserverInDB = () => {

  //     this.state.arrayvalueforobserver.forEach((element) => {
  //         fetch(`${process.env.REACT_APP_API_URL}SelectAllObserver`, {
  //             method: 'post',
  //             headers: { 'Content-Type': 'application/json' },
  //             body: JSON.stringify({
  //                 "email": element,
  //                 "id": this.props.location.state.fileID
  //             }),
  //         })
  //             .then(response => response.json())
  //             .then(response => this.setState({ responseFromAllObserver: response.data }))
  //             .catch(err => console.error(err));

  //         fetch(`${process.env.REACT_APP_API_URL}observermail?email=` + element + '&id=' + this.props.location.state.hash)
  //             .catch(err => console.error(err))
  //     })

  //     window.location.reload();

  // }

  // CheckMailForReviewerInDB = () => {
  //     this.state.arrayValueofReviewer.forEach((element) => {
  //         fetch(`${process.env.REACT_APP_API_URL}SelectAllReviewer`, {
  //             method: 'post',
  //             headers: { 'Content-Type': 'application/json' },
  //             body: JSON.stringify({
  //                 "email": element,
  //                 "id": this.props.location.state.fileID
  //             }),
  //         })
  //             .then(response => response.json())
  //             .then(response => this.setState({ responseFromAllReviewer: response.data }))
  //             .catch(err => console.error(err));
  //     })

  //     window.location.reload();

  // }

  hyperledger_Function() {
    //Temporary Hide
    const fileName = this.props.location.state.fileName;
    const hash = this.props.location.state.hash;
    const fileID = this.props.location.state.fileID;
    const CreatedBy = this.props.location.state.CreatedBy;
    const category = this.props.location.state.selectMenu;
    const description = this.props.location.state.description;

    this.state.selectSignerFromDB.forEach((element) =>
      signerarrayforHL.push({
        id: element.id,
        email: element.mailID,
      })
    );

    this.state.selectObserverFromDB.forEach((element) =>
      observerarrayforHL.push(element.mailID)
    );
    this.state.selectReviewerFromDB.forEach((element) =>
      reviewerarrayforHL.push(element.mailID)
    );
    // if (this.state.flag1 === false && this.state.selectSignerFromDB.length > 0 || this.state.selectObserverFromDB.length > 0 || this.state.selectReviewerFromDB.length > 0) {
    //     this.setState({
    //         flag1: false,
    //         flag2: true,
    //         flag3: false,
    //         flag4: false
    //     })

    // fetch(`${process.env.REACT_APP_API_URL}addsignertohyperledger`, {
    //   method: "post",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({
    //     emailArrayofsigner: signerarrayforHL,
    //     emailArrayofobserver: observerarrayforHL,
    //     emailArrayofreviewer: reviewerarrayforHL,
    //     fileName: fileName,
    //     hash: hash,
    //     fileID: fileID,
    //     category: category,
    //     description: description,
    //     CreatedBy: CreatedBy,
    //   }),
    // })
    //   .then((response) => response.json())
    //   .then((response) =>
    //     this.setState({ responseFromsignerHyperledger: response.data })
    //   )
    //   .catch((err) => console.error(err));

    //     this.state.selectSignerFromDB.forEach((element) =>
    //         MailArray.push(element.mailID)
    //     )

    //     this.state.selectObserverFromDB.forEach((element) =>
    //         MailArray.push(element.mailID)
    //     )
    //     this.state.selectReviewerFromDB.forEach((element) =>
    //         MailArray.push(element.mailID)
    //     )
    // } else {
    //     alert("Please add at least signer,Observer or reviewer")
    // }

    // console.log(signerarrayforHL)
    // console.log(observerarrayforHL)
    // console.log(reviewerarrayforHL)

    this.props.history.push({
      pathname: "/placeholderlanding",
      state: {
        fileid: this.props.location.state.fileID,
        hash1: this.props.location.state.hash,
        signerarray: signerarrayforHL,
        observerarray: observerarrayforHL,
        reviewerarray: reviewerarrayforHL,
      },
    });
  }

  render() {
    const { open } = this.state;
    const { openObserver } = this.state;
    const { openReviewer } = this.state;

    // const { openSigner } = this.state;

    const { name, email } = this.state;
    const enabled = email.length > 0 && name.length > 0;
    const enablednextpage = this.state.selectSignerFromDB.length > 0;

    // const enabled = this.state.arrayValueofSigner.length > 0
    const a = this.state.flag1;
    const b = this.state.flag2;
    const c = this.state.flag3;
    const d = this.state.flag4;

    const enabledDone = this.state.arrayValueofSigner.length > 0;
    const enableObserver = this.state.arrayvalueforobserver.length > 0;
    const enableReviewer = this.state.arrayValueofReviewer.length > 0;

    // const SignerValid = this.state.email.length > 0 && this.state.list.length > 0

    return (
      <div className="App">
          <ToastContainer />
        <Header />
        
        <Container as="div" fluid>
          <div className="app-layout pattern">
            <Card as="div">
              <Card.Body as="div">
                <Card.Title as="div">
                  <Row as="div">
                    <div>
                      <Button
                        outline
                        color="primary"
                        className="m-2"
                        disabled={a}
                      >
                        Initiate Signing
                      </Button>
                    </div>
                    {/* <div>
                                            <Button outline color="secondary" className="m-2" disabled={!b} >Signature Needed</Button>
                                        </div>
                                        <div>
                                            <Button outline color="success" className="m-2" disabled={!c} >Placeholders </Button>
                                        </div>
                                        <div>
                                            <Button outline color="info" className="m-2" disabled={!d}>Review &amp; Send</Button>
                                        </div> */}
                    <Col as="div" className="text-right">
                      {/* <Button className="m-2"  >Close</Button> */}
                      <button
                        className="btn btn-primary"
                        onClick={this.hyperledger_Function}
                        disabled={!enablednextpage}
                      >
                        Save & Next
                      </button>
                    </Col>
                  </Row>
                </Card.Title>
                <Card.Text as="div">
                  <Row as="div">
                    <Col as="div" sm={12}>
                      <section>
                        <div className="container-fluid">
                          <div className="row clearfix">
                            <div className="col-lg-3 col-md-3 col-sm-12">
                              <div className="card product_item">
                                <div className="body">
                                  <div className="cp_img">
                                    <img
                                      src={ecommmerce1}
                                      alt="Product"
                                      className="img-fluid"
                                    />
                                    <div className="hover">
                                      <a className="btn btn-primary btn-sm waves-effect">
                                        <i className="zmdi zmdi-cloud-download" />
                                      </a>
                                      <a className="btn btn-warning btn-sm waves-effect">
                                        <i className="zmdi zmdi-comment-edit" />
                                      </a>
                                      <a className="btn btn-danger btn-sm waves-effect">
                                        <i className="zmdi zmdi-delete" />
                                      </a>
                                    </div>
                                  </div>
                                  <div className="product_details">
                                    <h5>
                                      <a href="">
                                        {this.props.location.state.fileName}
                                      </a>
                                    </h5>
                                    <ul className="product_price list-unstyled">
                                      <li className="old_price"></li>
                                      <li className="new_price">
                                        {this.state.AccessFileHashDb.map(
                                          (item) => item.UpdatedAt
                                        )}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-5 col-md-4 col-sm-12">
                              <div className="body">
                                <form>
                                  <label htmlFor="email_address">
                                    Document Name
                                  </label>
                                  <div className="form-group">
                                    <textarea
                                      name="docName"
                                      id="doc_name"
                                      className="form-control"
                                      placeholder="Test Document"
                                      cols={40}
                                      rows={1}
                                      defaultValue={
                                        this.props.location.state.fileName
                                      }
                                      readOnly
                                    />
                                  </div>
                                  <label htmlFor="password">
                                    Document Category
                                  </label>
                                  <div className="form-group">
                                    <textarea
                                      name="docCategory"
                                      id="doc_Category"
                                      className="form-control"
                                      cols={40}
                                      rows={1}
                                      defaultValue={ this.props.location.state.category
                                      }
                                      readOnly
                                    />
                                  </div>
                                  <label htmlFor="email_address">
                                    Document Description
                                  </label>
                                  <div className="form-group">
                                    <textarea
                                      name="docDesc"
                                      id="doc_name"
                                      className="form-control"
                                      placeholder="Not Specified"
                                      cols={40}
                                      rows={5}
                                      defaultValue={this.props.location.state.description
                                      }
                                      readOnly
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <ListGroup as="div">
                                  <h5 style={{ fontFamily: "monospace" }}>
                                    {" "}
                                    Signer:
                                  </h5>
                                  <div>
                                    <ul className="product_price list-unstyled">
                                      <li
                                        className="new_price"
                                        style={{ color: "red" }}
                                      >
                                        {this.state.updateforsigner}
                                      </li>
                                      <li
                                        className="new_price"
                                        style={{ color: "green" }}
                                      >
                                        {this.state.updateforsamesigner}
                                      </li>
                                    </ul>
                                  </div>
                                  {this.state.selectSignerFromDB.map(
                                    (item, key) => (
                                      <ListGroupItem key={key}>
                                        {item.User.email}
                                      </ListGroupItem>
                                    )
                                  )}
                                </ListGroup>

                                <ListGroup as="div">
                                  <h5 style={{ fontFamily: "monospace" }}>
                                    {" "}
                                    Observer:
                                  </h5>
                                  <div>
                                    <ul className="product_price list-unstyled">
                                      <li
                                        className="new_price"
                                        style={{ color: "red" }}
                                      >
                                        {this.state.updateofobserver}
                                      </li>
                                      <li
                                        className="new_price"
                                        style={{ color: "green" }}
                                      >
                                        {this.state.updateofsameobserver}
                                      </li>
                                    </ul>
                                  </div>
                                  {this.state.selectObserverFromDB.map(
                                    (item) => (
                                      <ListGroupItem>
                                        {item.User.email}
                                      </ListGroupItem>
                                    )
                                  )}
                                </ListGroup>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="card member-card">
                                <div
                                  className="header l-blush"
                                  style={{ minHeight: "90px" }}
                                >
                                  <h4 className="m-t-0">Signers</h4>
                                </div>
                                <div
                                  className="member-img"
                                  style={{ marginTop: "-36px" }}
                                >
                                  <a href="profile.html" className>
                                    <img
                                      className="rounded-circle"
                                      src={signature}
                                      alt="profile-image"
                                      style={{ width: "78px" }}
                                    />
                                  </a>
                                </div>
                                <div className="body">
                                  <div className="col-12">
                                    <h6>Select Existing Signer :</h6>

                                    <Picky
                                      value={this.state.arrayValueofSigner}
                                      options={this.state.dropdownlistforsigner}
                                      onChange={this.selectMultipleOption}
                                      open={false}
                                      valueKey="id"
                                      labelKey="email"
                                      multiple={true}
                                      includeSelectAll={true}
                                      includeFilter={true}
                                      dropdownHeight={600}
                                      placeholder="- - - Please Select - - -"
                                    />
                                  </div>
                                  <hr />
                                  <div className="row">
                                    <div className="col-4">
                                      <h5>
                                        <i
                                          style={{
                                            color: "#888888",
                                            fontSize: "-webkit-xxx-large",
                                          }}
                                          className="zmdi zmdi-help"
                                        />
                                      </h5>
                                      <small>Help</small>
                                    </div>
                                    <div className="col-4">
                                      <button
                                        className="btn btn-success btn-round waves-effect zmdi zmdi-bookmark"
                                        disabled={!enabledDone}
                                        type="button"
                                        onClick={() =>
                                          this.assignUserTypeToFile(
                                            "Signer",
                                            this.state.selectedUserId
                                          )
                                        }
                                      >
                                        Done{" "}
                                      </button>
                                    </div>
                                    <div className="col-4">
                                      <h5>
                                        <button
                                          className="btn btn-info btn-round waves-effect zmdi zmdi-edit"
                                          onClick={this.onOpenModal}
                                          type="button"
                                        >
                                          New{" "}
                                        </button>
                                      </h5>
                                      <Modal as="div" open={open} center>
                                        <Form
                                          as="div"
                                          style={{ width: "600px" }}
                                          onSubmit={this.handleSubmit}
                                          method="POST"
                                        >
                                          <FormGroup as="div">
                                            <Label for="name">FirstName</Label>
                                            <Input
                                              type="text"
                                              name="name"
                                              onChange={this.handleChange}
                                              placeholder="Enter Your FirstName"
                                            />
                                          </FormGroup>
                                          <FormGroup as="div">
                                            <Label for="name">LastName</Label>
                                            <Input
                                              type="text"
                                              name="Lastname"
                                              onChange={this.handleChange}
                                              placeholder="Enter Your LastName"
                                            />
                                          </FormGroup>
                                          <FormGroup as="div">
                                            <Label for="email">Email</Label>
                                            <Input
                                              type="email"
                                              name="email"
                                              onChange={this.handleChange}
                                              placeholder="Enter Your Email"
                                            />
                                          </FormGroup>
                                          <FormGroup as="div">
                                            <Label for="type">Type</Label>
                                            {/* <Input type="text" name="type" onChange={this.handleChange} placeholder="Enter User Type" /> */}
                                            <Input
                                              id="exampleSelect"
                                              name="select"
                                              type="select"
                                              onchange={this.handleChange}
                                            >
                                              <option>Internal</option>
                                              <option>Guest</option>
                                           
                                            </Input>
                                          </FormGroup>

                                          <Button disabled={!enabled}>
                                            Submit
                                          </Button>
                                        </Form>
                                        <Button
                                          style={{ backgroundColor: "red" }}
                                          onClick={this.onCloseModal}
                                        >
                                          close
                                        </Button>
                                      </Modal>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="card member-card">
                                <div
                                  className="header l-amber"
                                  style={{ minHeight: "90px" }}
                                >
                                  <h4 className="m-t-0">Observer</h4>
                                </div>
                                <div
                                  className="member-img"
                                  style={{ marginTop: "-36px" }}
                                >
                                  <a href="profile.html" className>
                                    <img
                                      className="rounded-circle"
                                      src={observer}
                                      alt="profile-image"
                                      style={{ width: "78px" }}
                                    />
                                  </a>
                                </div>
                                <div className="body">
                                  <div className="col-12">
                                    <h6>Select Existing Observer :</h6>
                                    <Picky
                                      value={this.state.arrayvalueforobserver}
                                      options={this.state.dropdownforobser}
                                      onChange={
                                        this.selectMultipleOptionForObserver
                                      }
                                      open={false}
                                      valueKey="id"
                                      labelKey="email"
                                      multiple={true}
                                      includeSelectAll={true}
                                      includeFilter={true}
                                      dropdownHeight={600}
                                      placeholder="- - - please select - - -"
                                    />
                                  </div>
                                  <hr />
                                  <div className="row">
                                    <div className="col-4">
                                      <h5>
                                        <i
                                          style={{
                                            color: "#888888",
                                            fontSize: "-webkit-xxx-large",
                                          }}
                                          className="zmdi zmdi-help"
                                        />
                                      </h5>
                                      <small>Help</small>
                                    </div>
                                    <div className="col-4">
                                      <button
                                        className="btn btn-primary btn-round waves-effect zmdi zmdi-bookmark"
                                        onClick={() =>
                                          this.assignUserTypeToFile(
                                            "Observer",
                                            this.state.selectedObserverId
                                          )
                                        }
                                        disabled={!enableObserver}
                                        type="button"
                                      >
                                        Done{" "}
                                      </button>
                                    </div>
                                    <div className="col-4">
                                      <h5>
                                        <button
                                          className="btn btn-info btn-round waves-effect zmdi zmdi-eye"
                                          onClick={this.onOpenModalForObserver}
                                          type="button"
                                        >
                                          New{" "}
                                        </button>
                                      </h5>
                                      <Modal open={openObserver} center>
                                        <Form
                                          style={{ width: "600px" }}
                                          onSubmit={
                                            this.handleSubmitForObserver
                                          }
                                          method="POST"
                                        >
                                          <FormGroup>
                                            <Label for="name">Name</Label>
                                            <Input
                                              type="text"
                                              name="name"
                                              onChange={this.handleChange}
                                              placeholder="Enter Your Name"
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="email">Email</Label>
                                            <Input
                                              type="email"
                                              name="email"
                                              onChange={this.handleChange}
                                              placeholder="Enter Your Email"
                                            />
                                          </FormGroup>
                                          <Button disabled={!enabled}>
                                            Submit
                                          </Button>
                                        </Form>
                                        <Button
                                          style={{ backgroundColor: "red" }}
                                          onClick={this.onCloseModalForObserver}
                                        >
                                          close
                                        </Button>
                                      </Modal>
                                    </div>
                                    {/* <div className="col-4">
                                                                            
                                                                            <h5><i style={{ color: '#104775', fontSize: '-webkit-xxx-large' }} className="zmdi zmdi-eye" /></h5>
                                                                            <small>select Observer</small>
                                                                        </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="card member-card">
                                <div
                                  className="header l-blush"
                                  style={{ minHeight: "90px" }}
                                >
                                  <h4 className="m-t-0">Reviewer</h4>
                                </div>
                                <div
                                  className="member-img"
                                  style={{ marginTop: "-36px" }}
                                >
                                  <a href="profile.html" className>
                                    <img
                                      className="rounded-circle"
                                      src={signature}
                                      alt="profile-image"
                                      style={{ width: "78px" }}
                                    />
                                  </a>
                                </div>
                                <div className="body">
                                  <div className="col-12">
                                    <h6>Select Existing Reviewer :</h6>

                                    <Picky
                                      value={this.state.arrayValueofReviewer}
                                      options={
                                        this.state.dropdownlistforReviewer
                                      }
                                      onChange={
                                        this.selectMultipleOptionForReviewer
                                      }
                                      open={false}
                                      valueKey="id"
                                      labelKey="email"
                                      multiple={true}
                                      includeSelectAll={true}
                                      includeFilter={true}
                                      dropdownHeight={600}
                                      placeholder="- - - Please Select - - -"
                                    />
                                  </div>
                                  <hr />
                                  <div className="row">
                                    <div className="col-4">
                                      <h5>
                                        <i
                                          style={{
                                            color: "#888888",
                                            fontSize: "-webkit-xxx-large",
                                          }}
                                          className="zmdi zmdi-help"
                                        />
                                      </h5>
                                      <small>Help</small>
                                    </div>
                                    <div className="col-4">
                                      <button
                                        className="btn btn-success btn-round waves-effect zmdi zmdi-bookmark"
                                        onClick={() =>
                                          this.assignUserTypeToFile(
                                            "Reviewer",
                                            this.state.selectedReviewerId
                                          )
                                        }
                                        disabled={!enableReviewer}
                                        type="button"
                                      >
                                        Done{" "}
                                      </button>
                                    </div>
                                    <div className="col-4">
                                      <h5>
                                        <button
                                          className="btn btn-info btn-round waves-effect zmdi zmdi-edit"
                                          onClick={this.onOpenModalForReviewer}
                                          type="button"
                                        >
                                          New{" "}
                                        </button>
                                      </h5>
                                      <Modal open={openReviewer} center>
                                        <Form
                                          style={{ width: "600px" }}
                                          onSubmit={
                                            this.handleSubmitForReviewer
                                          }
                                          method="POST"
                                        >
                                          <FormGroup>
                                            <Label for="name">Name</Label>
                                            <Input
                                              type="text"
                                              name="name"
                                              onChange={this.handleChange}
                                              placeholder="Enter Your Name"
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="email">Email</Label>
                                            <Input
                                              type="email"
                                              name="email"
                                              onChange={this.handleChange}
                                              placeholder="Enter Your Email"
                                            />
                                          </FormGroup>

                                          <Button disabled={!enabled}>
                                            Submit
                                          </Button>
                                        </Form>
                                        <Button
                                          style={{ backgroundColor: "red" }}
                                          onClick={this.onCloseModalForReviewer}
                                        >
                                          close
                                        </Button>
                                      </Modal>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <ListGroup>
                                <h5 style={{ fontFamily: "monospace" }}>
                                  {" "}
                                  Reviewer:
                                </h5>
                                <div>
                                  <ul className="product_price list-unstyled">
                                    <li
                                      className="new_price"
                                      style={{ color: "red" }}
                                    >
                                      {this.state.updateforReviewer}
                                    </li>
                                  </ul>
                                </div>
                                {this.state.selectReviewerFromDB.map((item) => (
                                  <ListGroupItem>
                                    {item.User.email}
                                  </ListGroupItem>
                                ))}
                              </ListGroup>
                            </div>
                          </div>
                        </div>
                      </section>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    );
  }
}
export default withRouter(Signers);
