import React, { Component } from "react";
import checklist from "./assets/html/images/checklist.svg";
import "./assets/html/css/main.css";
import "./assets/html/css/hm-style.css";
import "./assets/html/css/color_skins.css";
import { withRouter } from "react-router-dom";
//import { Button } from 'react-bootstrap';
import ipfsClient from "ipfs-http-client";
import Modal from "react-responsive-modal";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import toastr from "reactjs-toastr";
import { Card, Container, Col, Row, Button, Table } from "react-bootstrap";
import Header from "./components/Header";
import { ToastContainer, toast } from 'react-toastify';    
import 'react-toastify/dist/ReactToastify.css';


class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // companyId: []
      users: [],
      firstName : [],
      lastName: [],
      email: [],
      type: 'Internal'
    };

    // this.openDocument = this.openDocument.bind(this);
    // this.getAwaitingOther = this.getAwaitingOther.bind(this);
  }


  getUsers() {
    
    Axios.get(process.env.REACT_APP_API_URL + 'getUser?company=true&companyId='+ sessionStorage.getItem('companyId')).then(users => {
      this.setState({
        users: users.data
      })
    }).catch(err => {
        console.log(err);
    })

  }


  createUser() {
    const users = {
      companyId: sessionStorage.getItem('companyId'),
      firstName : this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      type: this.state.type
    }

    console.log(users);
    if(this.state.firstName !== null && this.state.lastName !== null && this.state.email !== null && this.state.type !== null ) {
      Axios.post(process.env.REACT_APP_API_URL + 'createUser', users ).then(user => {
        toast.success(' Created User Successfully' );
        this.getUsers();
      }).catch(err => {
        console.log(err);
      })
    } else {
      alert('Select All Fields');
    }

   
   
  }


  componentDidMount() {

    this.getUsers();
  
  }
  uploadCSV(event) {
    console.log(event.target.files[0]);
  }

  handleFirstNameChange(event) {
    console.log(event.target.value);
    this.setState({
      firstName: event.target.value
    })
  }
  handleLastNameChange(event) {
    console.log(event);
    this.setState({
      lastName: event.target.value
    })
  }
  handleEmailChange(event) {
    console.log(event);
    this.setState({
      email: event.target.value
    })
  }
  handleTypeChange(event) {
    console.log(event);
    this.setState({
      type: event.target.value
    })
  }


 



  render() {
    // if (this.state.loggedIn === false) {
    //     return <Redirect to="/" />
    //   }
    const customCss = `
    input[type="file" i] {
      background: transparent;
    }
    `
    return (
      <div className="App">
        <style>
        {customCss}
        </style>
        <ToastContainer />
        <Header />
        <Container as="div" fluid>
          {/* <div className="title">
                Users
            </div> */}
          <div className="">
            <Card as="div" className="mt-4">
              <Row as="div" className="px-5 py-3">
                <div className="col-md-4">
                  <div className="form-group">
                    <label for="">First Name</label>
                    <input
                      type="text"
                      name=""
                      id=""
                      className="form-control"
                      placeholder=""
                      aria-describedby="helpId"
                      onChange={(e) => this.handleFirstNameChange(e)}
                    />
                    <small id="helpId" className="text-muted">
                      Help text
                    </small>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label for="">Last Name</label>
                    <input
                      type="text"
                      name=""
                      id=""
                      className="form-control"
                      placeholder=""
                      aria-describedby="helpId"
                      onChange={(e) => this.handleLastNameChange(e)}
                    />
                    <small id="helpId" className="text-muted">
                      Help text
                    </small>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label for="">Email</label>
                    <input
                      type="text"
                      name=""
                      id=""
                      className="form-control"
                      placeholder=""
                      aria-describedby="helpId"
                      onChange={(e) => this.handleEmailChange(e)}
                    />
                    <small id="helpId" className="text-muted">
                      Help text
                    </small>
                  </div>
                </div>
              </Row>
              <Row as="div" className="px-5">
              
                <div className="col-md-4">
                  <div className="form-group">
                    <label for="">User Type</label>
                    <select onChange={(e) => this.handleFirstNameChange(e)} className="form-control" name="" id="">
                      <option>Internal</option>
                      <option>External</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4"></div>

                <div className="col-md-4">
                  <div className="float-right">
                    <button onClick={() => {this.createUser()}} type="button" className="btn btn-primary">
                      Save
                    </button>

                    <input type="file" onChange={(e) => this.uploadCSV(e)} style={{width: "150px"}} className="btn btn-primary">
                    </input>
                    

                  </div>
                </div>
              </Row>

              <Row as="div">
                <Container fluid className="px-4 pb-4 pt-5">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>User Type</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.users.map((tile, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{tile.firstName}</td>
                        <td>{tile.lastName}</td>
                        <td>{tile.userType}</td>
                        <td>{tile.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                </Container>
              </Row>
            </Card>
          </div>
        </Container>
      </div>
    );
  }
}
export default withRouter(Users);
