import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
// import Login from './DocumentsLanding';
import * as serviceWorker from './serviceWorker';
import App from './App';
import NavBar from './asign';
// import Signature from './Signature';
// import Signers from './Signers';


// ReactDOM.render(<Login />, document.getElementById('root'));
// ReactDOM.render(<Signature />, document.getElementById('root'));
// ReactDOM.render(<Signers />, document.getElementById('root'));
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
