import React, { Component } from 'react';
// import './App.scss';
import { useHistory } from 'react-router-dom'
import Axios from "axios";
import { Image, Navbar, Nav } from 'react-bootstrap';

import './new.css'
export default class DocumentsLanding extends Component {
    // history = useHistory();
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            phone_no: ''
        }
    }

    login = () => {
        this.props.history.push('/login');
    }


    handleNameChange = (event) => {
        this.setState({ name: event.target.value })
    }

    handleEmailChange = (event) => {
        this.setState({ email: event.target.value })
    }
    handlePhoneNoChange = (event) => {
        this.setState({ phone_no: event.target.value })
    }

    saveEnquiry = () => {
        const body = {
            name: this.state.name,
            email: this.state.email,
            phone_no: this.state.phone_no
        };

        if (this.state.name !== null && this.state.name !== '' && this.state.email !== null && this.state.email !== '' && this.state.phone_no !== null && this.state.phone_no !== '') {
            Axios.post(process.env.REACT_APP_API_URL + 'enquiry', body).then(enquiry => {
                console.log(enquiry);
                alert(enquiry.data.status)
            }).catch(err => {
                console.log(err);
            })
        } else {
            alert('Fill the complete form.')
        }

    }

    render() {
        const { open } = this.state;
        return (

            <div className="App">

                <div className=" section1 h-100 py-4">
                    <div className="container py-5">
                        <div className="row py-5  ">
                            <div className="col-md-8  ">
                                <h1 className="digital-sign">DSign</h1>
                                <h4 className="digital-sign">
                                    Collabarative Document Signing & Management Platform
                                    {/* <!-- SAFE & SECURE CONTINUOUS BUSINESS EASY-TO-USE DIGITAL TRANSACTION PLATFORM --> */}
                                </h4>
                                <div className="mt-5">
                                    <button type="button" onClick={this.login} className="btn-lg w-25 btn-dark">Login</button>

                                    {/* <div className="col-sm">
                                        <h6 className="pt-3">Register Here</h6>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-4 ">
                                <img className="" src={require('./assets/blockchain.png')} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section2 h-100">
                    <div className="container ">

                        <div className="row  mt-5 ">
                            <div className="col-md-4 text-center">
                                <div className="justify-content-center">
                                    <img className="" src={require('./assets/secure-payment.png')} alt="" />
                                </div>
                            </div>

                            <div className="col-md-4 text-center">
                                <img className="" src={require('./assets/europe-africa.png')} alt="" />
                            </div>


                            <div className="col-md-4 text-center">
                                <img className="" src={require('./assets/graph-increase.png')} alt="" />
                            </div>
                        </div>
                        <div className="container">
                            <div className="row py-5 pb-5">
                                <div className="col-md-4">
                                    <div className="card ml-3 text-center border-0 rounded-lg" style={{ width: '18rem' }}>
                                        <div className="card-body">
                                            <h5 className="card-title">Secure</h5>
                                            <p className="card-text">Only signatures are allowed, no change in document, no multiple documents, contents cannot be changed</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card ml-4 text-center border-0 rounded-lg" style={{ width: '18rem' }}>
                                        <div className="card-body">
                                            <h5 className="card-title">Accessibilty</h5>
                                            <p className="card-text">Ability for users to electronically sign documents and capture legally-binding digital signatures. Single document flow.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card ml-5 text-center border-0 rounded-lg" style={{ width: '18rem' }}>
                                        <div className="card-body">
                                            <h5 className="card-title">Scalability</h5>
                                            <p className="card-text">Solution based on IPFS open source technology. It is Blockchain-ready by virtue of its encryption characteristics. It stores files in distributed way ensuring encryption.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section1">
                    <div className="container ">
                        <div className="row py-5  mt-5">
                            <div className="col-md-6">
                                <img src={require('./assets/control-panel.png')} alt="" />
                            </div>
                            <div className="col-md-6 easytouse">
                                <h3>Ease to Use</h3>
                                <h5 style={{ fontSize: "20px", fontFamily: 'sans-serif' }}>
                                    One instance, no need of email forwards or CC's and it is a single source of truth same set of data is available across multiple nodes so Data cannot be fudged or manipulated. Solution can be integrated with any Enterprise solutions.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container ">
                    <div className="row py-5  mt-5">
                        <div className="col-md-6">
                            <h3>Collaborative</h3>
                            <h5 style={{ fontSize: "20px", fontFamily: 'sans-serif' }}>
                                Web-based platform along with mobile apps User can scan, sign and view/manage documents from anywhere. Electronic signature services and a seamless signing process for individuals and business users alike.
                            </h5>
                        </div>
                        <div className="col-md-6 pb-5 ">
                            <img className="h-100 w-100 " src={require('./assets/work-together.png')} alt="" />
                        </div>
                    </div>

                </div>

                <div className="section1">
                    <div className="container">
                        <div className="row py-5">
                            <div className="col-md-6">
                                <div className="card  text-white">
                                    <div className="card-body rounded-3 card-custom">
                                        <h5 className="card-title text-lg-start pb-5">CONTACT INFORMATION</h5>
                                        {/* <!-- <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6> --> */}
                                        <div className="row">
                                            <i className="bi bi-telephone-fill w-auto pb-5"></i>
                                            <h6 className="w-auto">+918149015040</h6>
                                        </div>
                                        <div className="row">
                                            <i className="bi bi-envelope-fill w-auto pb-5"></i>
                                            <h6 className="w-auto">info@executive81.com</h6>
                                        </div>
                                        <div className="row">
                                            <i className="bi bi-geo-alt-fill w-auto pb-5"></i>
                                            <h6 className="w-auto">Pune</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="registerForm"> Register Here:</label>
                                <form>
                                    <div className="form-group">
                                        <label for="exampleInputName1">Name</label>
                                        <input type="Name" className="mb-4 shadow form-control" id="exampleInputName1" placeholder="Name" value={this.state.name} onChange={this.handleNameChange} />
                                    </div>
                                    <div className="form-group">
                                        <label for="exampleInputEmail1">Email address</label>
                                        <input type="email" className="mb-4 shadow form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" value={this.state.email} onChange={this.handleEmailChange} />
                                    </div>
                                    <div className="form-group">
                                        <label for="exampleInputPhoneNumber1">Phone Number</label>
                                        <input type="phone" className="mb-4 shadow form-control" id="exampleInputPhoneNumber1" aria-describedby="" placeholder="Enter Phone Number" value={this.state.phone_no} onChange={this.handlePhoneNoChange} />
                                        {/* <!-- <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> --> */}
                                    </div>
                                    <div className="form-group">
                                        <button type="button" className="btn btn-primzary float-end" onClick={this.saveEnquiry}>
                                            Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Footer --> */}
                <div className="footer mt-5">
                    {/* <!-- <img className="pt-5" src="assets/Vector.svg" alt=""> --> */}

                    {/* <!-- <div className="container">
            <div className="row">
                <div className="col">
                    <div className="row pt-5">
                        <span className="h6 mt-auto">
                        Powered by Dsign
                    </span>
                    </div>
                </div>
                <div className="col">
                    <div className="row pt-5">

                        <i className="bi bi-envelope-fill w-auto pb-5"></i>

                        <span className="h6">info@executive81.com</span>

                    </div>
                </div>
            </div>
        </div> --> */}
                </div>

            </div>
        )
    }
}