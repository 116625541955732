
import React from 'react';
//import { Redirect } from 'react-router-dom'
import ReactTable from "react-table";
import "react-table/react-table.css";
import "../src/assets/scss/_table.scss";

class TableDatamail extends React.Component {
  constructor(props) {
    super(props);

      this.state = {
      posts: [],
      mail: [],
      Versiondetails: [],
      targetId: null,
      mailId: null,
      hashMail: null,
      fileName: null,
      version: null,
      parentID: null,
      UpdatedAt: null,

    }
  }

  openIPFS(fileHash) {
    window.location.href = `${process.env.REACT_APP_API_URL}ipfs/` + fileHash;
  }
  componentDidMount() { 
 let url = new URL( process.env.REACT_APP_FRONTEND + 'demo?id=mycontent');

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
// Give the parameter a variable name
let dynamicContent = getParameterByName('id');
    alert(dynamicContent)
          fetch(`${process.env.REACT_APP_API_URL}mailselect?hash=`+dynamicContent)
          .then(response => response.json())
          .then(response => this.setState({ mail: response.data }))
          .catch(err => console.error(err))
  }
  render() {

    const columns = [
      {
        id: 'col1',
        columns: [
          {
            Header: 'Document Name',
            id: 'sub_col_1a',
            accessor: (d) => {
              return <div >
                {/* <div className="document-title" id={d.id} onClick={(e) => this.reply_click(e, d.id,d.fileName)} style={{ cursor: 'pointer' }} >{d.fileName}</div> */}
                <div className="document-title" id={d.id} onClick={(e) =>this.props.triggerChangeFile(d.fileName,d.id)} style={{ cursor: 'pointer' }} >{d.fileName}</div>
                <span onClick={() => this.openIPFS(d.hash)} class="document-id">{d.hash}</span>
              </div>
            },
            width: 500
          },
        ]
      },
      {
        Header: "Version",
        accessor: "version",
        width: 100

      },
      {
        Header: "Parent Id",
        accessor: "parentID",
        width: 100

      },

      {
        Header: 'Last Modified',
        accessor: 'UpdatedAt',
        width: 300

      },
    ]

    return (
      <React.Fragment>
        <ReactTable
          data={this.state.mail}
          columns={columns}
          // defaultPageSize={1}
          style={{
            height: "500px"
          }}
          showPagination={false}
          resizable={false}
          defaultSorted={[{ id: "UpdatedAt", desc: false }]}

        />
         </React.Fragment>

    );
  }
}

export default TableDatamail
