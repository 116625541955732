import React, { Component } from "react";
import Header from "./components/Header";
import { Card, Container, Col, Row } from "react-bootstrap";
export default class Thanks extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    window.setTimeout(function () {
      // Move to a new location or you can do something else
      window.location.href = process.env.REACT_APP_FRONTEND + "logout";
    }, 3000);
  }

  // componentDidMount=async() =>{
  //   console.log(this.props.location.state.emailofsigner)
  //   console.log(this.props.location.state.urltoshowsigndoc)

  //    await  fetch('http://localhost:4000/returnemail?email=' + this.props.location.state.emailofsigner + '&url=' + this.props.location.state.urltoshowsigndoc)
  //                         .then(response => response.json())
  //                         .catch(err => console.error(err))
  // }
  render() {
    return (
      <div className="App">
        <Header />
        <Container fluid>
          <div className="app-layout pattern">
            <Card>
              <Card.Body>
                <Card.Title>
                  <Row>
                    <Col className="page-title">
                      Thanks for Sign a Document...{" "}
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    );
  }
}
