
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { element } from 'prop-types';

class downloadFile extends Component {


    constructor(props) {
        super(props);
        this.state = {
            AwaitingsignedDocs:[],
            apikey: 'zBBsffFEHrHmZ772cVE8LClg2hNeld',
        }
        this.sayHello = this.sayHello.bind(this);
    }

    sayHello() {
        let request = require("request")
        let options = null
        options = {
            method: 'GET',
            url: 'https://api-ext.getsigneasy.com/v1/files/signed/',
            headers: {
                Authorization: 'Bearer ' + this.state.apikey,

            },
            json: true,

        }
         request(options, (error, response, body) => {
            if (error) throw new Error(error);
           // console.log(response.body.files)
            this.setState({
                checkfileid:response.body.files
            },()=>this.handleDownload())    
        })
    }

    handleDownload =()=>{
        
        console.log(this.state.checkfileid)
        this.state.checkfileid.map((element)=>{
            let request = require("request")
        let options = null
            console.log(element.pending_file.id+"="+element.id)
            options = {
                method: 'GET',
                url: 'https://api-ext.getsigneasy.com/v1/files/signed/'+element.id+'/download/',
                headers: {
                    Authorization: 'Bearer ' + this.state.apikey,
    
                },
                json: true,
                
    
            }
             request(options, (error, response, body) => {
                if (error) throw new Error(error);
              //  console.log(response.url)  
                setTimeout(() => {
                    const respons = {
                      file: response.url,
                    };
                    // server sent the url to the file!
                    // now, let's download:
                    // window.open(respons.file);
                    // you could also do:
                    // window.location.href = respons.file;
                  }, 1000);            
                              
                // this.setState({
                //     file:response
                // },()=>this.download())    
            })
        })
    }

//     download=()=>{
//         console.log(this.state.file.url)    
//           // fake server request, getting the file url as response
//   setTimeout(() => {
//     const response = {
//       file: this.state.file.url,
//     };
//     // server sent the url to the file!
//     // now, let's download:
//              window.open(response.file);
//     // you could also do:
//     // window.location.href = response.file;
//   }, 100);            

//     }
    render() {
        return (
            <Button onClick={this.sayHello}> Click me!  </Button>
        );
    }
}

export default downloadFile;