import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import logo from '../assets/images/innowise-logo.png';
import '../App.scss';
export default class Login extends Component {
  constructor(props) {
    super(props)
    // const token = localStorage.getItem("token")

    // let loggedIn = false
    // if (token == null) {
    //   loggedIn = false
    // }
    this.state = {
      login:[],
      username: '',
      password: '',
      loggedIn: false
    }
    this.onChange = this.onChange.bind(this)
    // this.submitForm = this.submitForm.bind(this)
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  // submitForm(e) {
  //   e.preventDefault();
  //   const { username, password } = this.state;

  //   this.InsertToDB(username, password)
  //   // this.state.loggedIn = true;
  //   // if (username === this.state.username && password === this.state.password) {
  //   //   localStorage.setItem("token", "jsjdfvkashdfvjdfkjkbddvv")
  //   //   this.InsertToDB(username, password)
  //   //   this.setState({
  //   //     loggedIn: true
  //   //   })
  //   // }
  // }

  InsertToDB(username, password) {
    fetch(`${process.env.REACT_APP_API_URL}login`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache", 
      credentials: "same-origin", 
      headers: {
          "Content-Type": "application/json; charset=utf-8",
      },
      redirect: "follow", 
      referrer: "no-referrer", 
      body: {
        'email': this.state.username,
        'password': this.state.password
      }
  }).then(function (res) {
      console.log(res);
      // localStorage.setItem('token',)
      return res.json();
  })
      .then(function (myJson) {
          console.log(myJson);
      });
      // .then(res => this.setState({ login: res.data }))
      // .catch(err => console.error(err))
  }

  gotoRegister =()=>{
    this.props.history.push({
        pathname:"/register",
        state:{
        }
       })
}
  render() {
    if (this.state.loggedIn) {
      return <Redirect to="/dash" />
    }
    return (
      <div className="App">
        <Container className="login" fluid>
          <Row>
            <Col className="login__left-panel">
              <Container fluid className="login__left-panel--image">
                <Image className="app-logo__image" src={logo} alt="Versa Logo" />
              </Container>
            </Col>
            <Col className="login__right-panel">
              <div className="login__right-panel--form">
                <Form onSubmit={this.InsertToDB('s','s')}>
                  <Form.Group controlId="formBasicEmail" className="error">
                    <Form.Control type="email" placeholder="username" name="username" value={this.state.username} onChange={this.onChange} />
                    <Form.Label for="email">Email</Form.Label>
                    {/* <Form.Control.Feedback type="invalid">
                                Error message
                               </Form.Control.Feedback> */}
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                    <Form.Control type="password" placeholder="password" name="password" value={this.state.password} onChange={this.onChange} />
                    <Form.Label for="password">Password</Form.Label>
                  </Form.Group>

                  <Form.Group controlId="formBasicOrganizationCode">
                    <Form.Control type="password" name="oc" id="oc" placeholder="Organization Code" />
                    <Form.Label for="oc">Organization Code</Form.Label>
                  </Form.Group>

                  <Button variant="primary" type="submit" >
                    Login
                             </Button>
                </Form>
                <div className="forgot-password-link">
                  <Button variant="link" onClick={this.gotoRegister}>Register</Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>



      

      </div>
    )
  }
}
