import React from 'react';
import './style.css';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import logo from '../assets/images/logo.png';
import Axios from 'axios';


let SystemUserEmail = [];
let SystemUserPassword = [];
require('dotenv').config();
export default class Login1 extends React.Component {
    constructor(props) {
        super(props);

        // axios.defaults.headers.common = {'Authorization': `Bearer ${local}`}

        // const token = sessionStorage.getItem("token")
        // let loggedIn = false
        // // if (token == null) {
        // //   loggedIn = false
        // // }
        this.state = {
            fields: {},
            errors: {},
            accessallemailofsystemuser: [],
            accessemailwithpassword: [],
            loggedIn: Boolean
        }

        this.handleChange = this.handleChange.bind(this);
        this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
        this.gotoRegisterPage = this.gotoRegisterPage.bind(this)

    };

    componentDidMount() {
        // fetch(`${process.env.REACT_APP_API_URL}selectalldataforSystemUser`)
        //     .then(response => response.json())
        //     .then(response => this.setState({ accessallemailofsystemuser: response.data }, () => console.log(this.state.accessallemailofsystemuser)))
        //     .catch(err => console.error(err))
    }

    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });

    }

    submituserRegistrationForm(e) {
        e.preventDefault();
        if (this.validateForm()) {
            let fields = {};
            fields["emailid"] = "";
            fields["password"] = "";
            this.setState({
                fields: fields
            });

            Axios.post(process.env.REACT_APP_API_URL+ 'login', {email: this.state.fields.emailid, password: this.state.fields.password}).then(res => {
                sessionStorage.setItem('token', res.data.access_token);
                console.log(res);
                if(res.data.token !== null) {
                    Axios.get(process.env.REACT_APP_API_URL + 'getUserDetails', {
                        headers: { 
                        'Content-Type':'application/json',
                        "Authorization": `Bearer ` + res.data.access_token 
                    }}).then(user => {
                        this.redirectTo();
                        sessionStorage.setItem('userId', user.data.id);
                        sessionStorage.setItem('email', user.data.email);
                        sessionStorage.setItem('companyId', user.data.companyId);
                        sessionStorage.setItem('user', user.data.firstName + ' ' + user.data.lastName);
                        console.log(user)
                    }).catch(err => {
                        console.log(err);
                    })
                    


                }
            }).catch(err => {
                console.log(err);
            })
        
          

            // if(this.state.accessallemailofsystemuser) {
            //     this.state.accessallemailofsystemuser.map((element) => SystemUserEmail.push(element.email))
            //     var emailCheck = SystemUserEmail.includes(this.state.fields.emailid)
            //     var emailofstate =this.state.fields.emailid
            //     var passwordofstate=this.state.fields.password
            //     console.log("result==" + emailCheck)
            //     fetch(`${process.env.REACT_APP_API_URL}checkForPassword?email=` + this.state.fields.emailid)
            //     .then(response => response.json())
            //       .then(response => this.setState({ accessemailwithpassword: response.data }, () =>
            //      this.checkfun(this.state.accessemailwithpassword,emailCheck,emailofstate,passwordofstate)
            //      ))
            //     .catch(err => console.error(err))                
            // } else {
            //     console.log('error')
            // }
    }
}


redirectTo = () => {
    // sessionStorage.setItem("token", 'sdad');
    this.props.history.push({
        pathname:"/dash",
        state:{
        }
       })
}

checkfun=(dbpass,boolemail,stemail,stpass)=>{  
          if(boolemail)
          {
            dbpass.map((element)=>{
                if(element.password === stpass)
                {
                    sessionStorage.setItem("token",stemail)
                    console.log(element.password,stpass)
                    this.props.history.push({
                        pathname:"/dash",
                        state:{
                        }
                       })
                }
                else{
                    alert("Incorrect password")
                }
            })
          }else{
              alert("Email is not Register")
          }
}

    validateForm() {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["emailid"]) {
            formIsValid = false;
            errors["emailid"] = "*Please enter your email-ID.";
        }
        if (typeof fields["emailid"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["emailid"])) {
                formIsValid = false;
                errors["emailid"] = "*Please enter valid email-ID.";
            }
        }
        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "*Please enter your password.";
        }
        this.setState({
            errors: errors
        });
        return formIsValid;


    }

    gotoRegisterPage() {
        this.props.history.push({
            pathname: "/register"
        })
    }
    render() {
        return (
            <div className="App">
                <Container className="login" fluid>
                    <Row>
                        <Col className="login__left-panel">
                            <Container fluid className="login__left-panel--image">
                                <Image className="app-logo__image" src={logo} alt="Versa Logo" />
                            </Container>
                        </Col>
                        <Col className="login__right-panel">
                            <div id="main-registration-container">
                                <div id="register">
                                    <form method="post" name="userRegistrationForm" onSubmit={this.submituserRegistrationForm} >
                                        <h5><strong>Email</strong></h5>
                                        <input type="text" placeholder="Enter the Email" name="emailid" value={this.state.fields.emailid} onChange={this.handleChange} />
                                        <div className="errorMsg">{this.state.errors.emailid}</div>
                                        <h5><strong>Password</strong></h5>
                                        <input type="password" placeholder="Enter the Password" name="password" value={this.state.fields.password} onChange={this.handleChange} />
                                        <div className="errorMsg">{this.state.errors.password}</div>
                                        <Button variant="primary" type="submit" style={{ width: "515px", height: "43px" }} className="button1">
                                            <span style={{ fontSize: "14px", fontStyle: "bold" }}>Login</span>
                                        </Button>
                                    </form>
                                    <Button variant="primary" onClick={this.gotoRegisterPage} type="submit" style={{ width: "515px", height: "43px" }} className="button2">
                                        <span style={{ fontSize: "14px", fontStyle: "bold" }} >SignUp</span>
                                    </Button>

                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
