import React, { Component } from "react";
import "./App.scss";
import Header from "./components/Header";
import TableData from "./TableData";
import VersionHistory from "./VersionHistory";
import VersionHistoryNotes from "./VersionHistoryNotes";
import { Button, Card, Container, Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import { Form, FormGroup, Input, Label } from "reactstrap";
import { resolveSoa } from "dns";
import axios from "axios";
import { placeholder } from "@babel/types";
import Placeholder from "./placeholder";
import PlaceholderSigner from "./placeholdersigner";

export default class PlaceholderLanding extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      hash: this.props.location.state.hash1,
      id: this.props.location.state.fileid,
      x_cord: null,
      y_cord: null,
      selectedsigner: [],
      pageNo: null,
      pageWithPlaceholderLocation: []
    };
  }

  triggersigner = async (x, y, pageno, actualx, actualy, pageWithPlaceholderLocation) => {
    await this.setState({
      x_cord: x,
      y_cord: y,
      pageNo: pageno,
      actualxcord: actualx,
      actualycord: actualy,
      pageWithPlaceholderLocation: pageWithPlaceholderLocation
    });
  };

  checkforselectionsigner = async (signer) => {
    await this.setState({
      selectedsigner: signer,
    });
  };

  render() {
    return (
      <div className="App">
          <ToastContainer />
        <Header />
        <Container as="div" fluid>
          <div className="aspp-layout pattern">
            <Card as="div">
              <Card.Body as="div">
                <Card.Title as="div">
                  <Row as="div">
                    <Col className="page-title"> Select Place... </Col>{" "}
                  </Row>{" "}
                </Card.Title>{" "}
                <Card.Text as="div">
                  <Row as="div">
                    <Col as="div" sm={7}>
                      <div className="left-panel data-content">
                        <Placeholder
                          selectedsigner={this.state.selectedsigner}
                          hash={this.state.hash}
                          triggerselectsigner={this.triggersigner}
                        />{" "}
                      </div>{" "}
                    </Col>{" "}
                    <Col as="div" sm={5} className="right-panel data-content">
                      <PlaceholderSigner
                        actualxcord={this.state.actualxcord}
                        actualycord={this.state.actualycord}
                        hash={this.state.hash}
                        triggerPlaceholder={this.checkforselectionsigner}
                        pageNo={this.state.pageNo}
                        id={this.state.id}
                        x_cord={this.state.x_cord}
                        y_cord={this.state.y_cord}
                        pageWithPlaceholderLocation= {this.state.pageWithPlaceholderLocation}

                      />{" "}
                    </Col>{" "}
                  </Row>{" "}
                </Card.Text>{" "}
              </Card.Body>{" "}
            </Card>{" "}
          </div>{" "}
        </Container>
      </div>
    );
  }
}
