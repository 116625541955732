import React, { Component } from "react";
import checklist from "./assets/html/images/checklist.svg";
import "./assets/html/css/main.css";
import "./assets/html/css/hm-style.css";
import "./assets/html/css/color_skins.css";
import { withRouter } from "react-router-dom";
//import { Button } from 'react-bootstrap';
import ipfsClient from "ipfs-http-client";
import Modal from "react-responsive-modal";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import toastr from "reactjs-toastr";
import { Card, Container, Col, Row, Button, Table } from "react-bootstrap";
import Header from "./components/Header";

class AwaitingOthers extends Component {
  constructor(props) {
    super(props);
    this.state = {
     docs: [] 
    }

    this.openDocument = this.openDocument.bind(this);
    // this.getAwaitingOther = this.getAwaitingOther.bind(this);
  }


getAwaitingOther() {
    Axios.get(process.env.REACT_APP_API_URL + 'awaitingDocToBeSigned' ).then(data => {
      this.setState({
        docs: data.data
      })
    }).catch(err => {
      console.log(err);
    })
}

openDocument(doc) {
  window.open(process.env.REACT_APP_IPFS_FILE + 'ipfs/' + doc)
}


componentDidMount() {

  this.getAwaitingOther();

}

  render() {
    // if (this.state.loggedIn === false) {
    //     return <Redirect to="/" />
    //   }
    return (
      <div className="App">
        <Header />
        <Container fluid>
            <Row>
                <Card className="px-4 pt-4" style={{"height": "400px"}}>
                <Table striped bordered hover>

                  
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>File Name</th>
                    <th>Hash</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.docs.map((tile, i) => (
                    <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{tile.fileName}</td>
                    <td onClick={() => this.openDocument(tile.hash)}>{tile.hash}</td>
                    <td>{tile.FileUserType.User.firstName}</td>
                    <td>{tile.FileUserType.User.lastName}</td>
                    <td>{tile.FileUserType.User.email}</td>
                  </tr>
                )) }

                </tbody>
              </Table>
                </Card>
            </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(AwaitingOthers);
