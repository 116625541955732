import React, { Component } from "react";
import "./App.scss";
import Header from "./components/Header";
import TableData from "./TableData";
import VersionHistory from "./VersionHistory";
import VersionHistoryNotes from "./VersionHistoryNotes";
import { Button, Card, Container, Col, Row } from "react-bootstrap";
import ipfsClient from "ipfs-http-client";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { resolveSoa } from "dns";
import Axios from "axios";
import { placeholder } from "@babel/types";
import Placeholder from "./placeholder";
import PlaceholderSigner from "./placeholdersigner";

// const ipfs = new ipfsClient({
//     host: "127.0.0.1",
//     port: "5001",
//     protocol: "http",
//   });

const ipfs = new ipfsClient({
  host: process.env.REACT_APP_HOST,
  port:  process.env.REACT_APP_PORT,
  protocol:  process.env.REACT_APP_PROTOCOL,
});


export default class Template extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
        hash: [],
    //   hash: this.props.location.state.hash1,
    //   id: this.props.location.state.fileid,
      x_cord: null,
      y_cord: null,
      actualX: [],
      actualY: [],
      selectedsigner: [],
      isUploaded: false,
      pageNo: null,
      templateName: [],
      pageWithPlaceholderLocation: []
    };
  }

  triggersigner = async (x, y, pageno, actualx, actualy, pageWithPlaceholderLocation) => {
    await this.setState({
      x_cord: x,
      y_cord: y,
      pageNo: pageno,
      actualxcord: actualx,
      actualycord: actualy,
      pageWithPlaceholderLocation: pageWithPlaceholderLocation
    });
  };

  checkforselectionsigner = async (signer) => {
    await this.setState({
      selectedsigner: signer,
    });
  };


  componentDidMount() {
      this.setState({
        selectedsigner: 'template@mail.com'
      })
  }

  onFileChange = (e) => {
      this.uploadDocumentToIPFS(e.target.files[0]);
    // this.setState(
    //   {
    //     fileUp: e.target.files[0],
    //   },
    //   () => console.log(this.state.fileUp)
    // );
  };


  async uploadDocumentToIPFS(file) {
    console.log(file);
    const fileAdded = await ipfs.add({
        content: file,
        wrapWithDirectory: true,
      });

      console.log(fileAdded);
      this.setState({
          hash: fileAdded[0].hash,
          isUploaded: true
      })
  }

  saveTemplate() {
      // Sent template x and y add them in placeholders table then retrieve the key for placeholder and save it in template table
      const templateData= {
        pageNo: this.state.pageno,
        companyId: sessionStorage.getItem('companyId'),
        templateName: this.state.templateName,
        pageWithPlaceholderLocation:  this.state.pageWithPlaceholderLocation
      }

      console.log(templateData);

      Axios.post(process.env.REACT_APP_API_URL + 'createTemplate', templateData).then(templates => {
       alert('Successfully Added') 
      }).catch(err => {
        alert(err)
      })
  }


  onTemplateName(e){
    console.log(e.target.value);
    this.setState({
      templateName: e.target.value
    })
  }


  render() {
    return (
      <div className="App">
        <Header />
        
        <Container as="div" fluid>
            <div className="card mt-5">
            <div className="row">
                <div class="form-group pl-5">
                  <label for="" className="h4">Upload Template  </label>
                  <input  type="file"
                            id="fileUp"
                            name="fileUp"
                            onChange={this.onFileChange}
                            />
                  {/* <small id="fileHelpId" class="form-text text-muted">Choose A file</small> */}
                </div>
            </div>
            </div>

        </Container>
        
        <Container as="div" fluid>
          <div className="aspp-layout pattern">
            <Card as="div">
                { this.state.isUploaded && 
              <Card.Body as="div">
               
                <Card.Text as="div">
                  <Row as="div">
                    <Col as="div" sm={7}>
                      <div className="left-panel data-content">

                        <Placeholder
                          selectedsigner={this.state.selectedsigner}
                          hash={this.state.hash}
                          triggerselectsigner={this.triggersigner}
                        />{" "}
                      </div>{" "}
                    </Col>{" "}
                    <Col as="div" sm={5} className="right-panel data-content">
                        <div className="row">
                            <div class="form-group pl-4">
                              <label for="">Template Description</label>
                              <input type="text" name="" onChange={(e) => this.onTemplateName(e)} class="form-control" placeholder="" aria-describedby="helpId"/>
                              <small id="helpId" class="text-muted">Write in 3 words</small>
                            </div>
                        </div>
                        <div className="row">
                            <input className="ml-4 btn" type="button" value="Save" onClick={() => this.saveTemplate()} />
                        </div>
                    </Col>{" "}
                  </Row>{" "}
                </Card.Text>{" "}
              </Card.Body>
                }
            </Card>{" "}
          </div>{" "}
        </Container>
      </div>
    );
  }
}
