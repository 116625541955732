import React, { Component } from "react";
import { Button } from "react-bootstrap";
import ReactTable from "react-table";
import Header from "./components/Header";
import { Card, Container, Col, Row } from "react-bootstrap";
import Axios from "axios";
import { Toast } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';    
import Loading from "react-fullscreen-loading";
// import Iframe from 'react-iframe'
require('dotenv').config();
export default class Awaiting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      AwaitingsignedDocs: [],
      accesssigneasyurl: [],
      signurl: [],
      hideSign: false,
      loading: false
    };
  }
  componentDidMount() {
    const token = sessionStorage.getItem("userId");
    Axios.get(process.env.REACT_APP_API_URL+ 'getAllMyDocuments?userId=' + token)
      // .then((response) => response.json())
      .then((response) => {
        console.log(response.data)
        this.setState({ AwaitingsignedDocs: response.data }, () => {})
    })
      .catch((err) => console.error(err));
  }


  batchSignature = () => {

    this.setState({loading: true})

    Axios.post(process.env.REACT_APP_API_URL + 'batchSignature').then(res => {

      console.log(res);
      if(res.data) {
        this.setState({loading: false})
        toast.success('Successfully Downloading !')
      } else if(res === undefined) {
        this.setState({loading: false});
        console.log(this.state.loading);
        toast.error('Technical error, report to administration');
      }
       
      window.open(res.data.zip)
      this.checkNextSigners();
    }).catch(err => {
      this.setState({loading: false});
      console.log(this.state.loading);
      toast.error('Technical error, report to administration');
    })
  }

  checkNextSigners = ()=> {
    this.state.AwaitingsignedDocs.forEach((element,index,arr) => {
      console.log(element.id);
      Axios.get(process.env.REACT_APP_API_URL + 'checkForNextSigners?fileId='+ element.FileHash.id).then(resp => {
        console.log(resp);
      }).catch(err => {
        console.log(err);
      })
    })
  }

  checktosigndocs = (e, id, filename, category, hash) => {
    const token = sessionStorage.getItem("token");
    // fetch(process.env.REACT_APP_API_URL+ 'checkforsigneasyurl?id='  + id + "&token=" + token)
    //   .then((response) => response.json())
    //   .then((response) =>
    //     this.setState({ accesssigneasyurl: response.data }, () =>
    //       this.state.accesssigneasyurl.map((element) => {
    //         this.props.history.push({
    //           pathname: "/EasySignature",
    //           state: {
    //             hash: hash,
    //             fileid: id,
    //             fileName: filename,
    //             signeasy_url: element.signeasy_url,
    //           },
    //         });
    //       })
    //     )
    //   )

    //   .catch((err) => console.error(err));

    this.props.history.push({
      pathname: "/EasySignature",
      state: {
        hash: hash,
        fileid: id,
        fileName: filename,
        // signeasy_url: element.signeasy_url,
      },
    });
  };
  render() {
   
    const columns = [
      {
        id: "col1",
        columns: [
          {
            Header: "Document Name",
            // accessor: 'FileHash.fileName',
            id: "sub_col_1a",
            accessor: (d) => {
              return (
                <div>
                  {" "}
                  {/* <div className="document-title" id={d.id} onClick={(e) => this.reply_click(e, d.id,d.fileName)} style={{ cursor: 'pointer' }} >{d.fileName}</div> */}{" "}
                  <div
                    className="document-title"
                    id={d.id}
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    {d.FileHash.fileName}{" "}
                  </div>{" "}
                  <span className="document-id"> {d.FileHash.hash} </span>{" "}
                </div>
              );
            },
            width: 500,
          },
        ],
      },

      {
        Header: "Invite to Sign A Document On Email",
        accessor: "User.email",
        width: 300,
      },

      {
        id: "col3",
        columns: [
          {
            Header: "action",
            id: "sub_col_3a",
            accessor: (d) => {
              return (
                <div>
                  {" "}
                  {/* <button id={d.id} onClick={(e)=>this.routeChange(e,d.id,d.fileName)} >Add Signers</button> */}
                  <Button
                    title="Go to Sign A Document"
                    id={d.id}
                    onClick={(e) =>
                      this.checktosigndocs(
                        e,
                        d.FileHash.id,
                        d.FileHash.fileName,
                        d.category,
                        d.FileHash.hash,
                        d.version,
                        d.UpdatedAt,
                        d.parentID,
                        d.signeasy_id
                      )
                    }
                  >
                    <span className="icon-edit"> Click Here To Sign </span>{" "}
                  </Button>
                </div>
              );
            },
            width: 150,
          },
        ],
      },
    ];

    return (
      <div className="App">
        
         <ToastContainer />
        <Header />
        
        <Container as="div" fluid>
        <Loading loading={this.state.loading}  background="#ffffff" loaderColor="#323c84" />
          <div className="app-layout pattern">
            <Card as="div">
              <Card.Body as="div">
                <Card.Title as="div">
                  <Row as="div">
                    <Col as="div" className="page-title">
                      {" "}
                      Awaiting Signers Documents{" "}
                    </Col>
                    <Col as="div" className="page-title">
                      {" "}
                     
                      <Button onClick={(e) => this.batchSignature()}>Sign All Documents</Button>{" "}
                    </Col>
                  </Row>{" "}
                </Card.Title >{" "}
                <Card.Text as="div">
                  <Row as="div">
                    <Col as="div" sm={12}>
                      <React.Fragment>
                        <ReactTable
                          data={this.state.AwaitingsignedDocs}
                          columns={columns}
                          // defaultPageSize={5}
                          showPagination={false}
                          style={{
                            height: "500px"
                          }}
                          resizable={false}
                          defaultSorted={[{ id: "UpdatedAt", desc: false }]}
                        />
                      </React.Fragment>{" "}
                    </Col>
                  </Row>{" "}
                </Card.Text>{" "}
              </Card.Body>{" "}
            </Card>{" "}
          </div>{" "}
        </Container>{" "}
      </div>
    );
  }
}
