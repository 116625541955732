import React, { useState, useRef, useEffect } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import ReactDOM from 'react-dom';

function MyPdfViewer (props, getNumber) {
  var [page,setPage] = useState(1);
  const [pdfNum, setPdfNum] = useState(1);
  const canvasRef = useRef(null);
  console.log(props)
  var { pdfDocument, pdfPage } = usePdf({
    file: props.pdf.pdfFile,
    page,
    canvasRef,
  });

  if(pdfDocument) {
    pdfDoc();
  }


  function pdfDoc()  {
    console.log('e')
    // getNumber(pdfDocument.numPages);
    // setPdfNum(pdfDocument.numPages);
    // props.pdf.pdfDoc(pdfNum);
    if(pdfDocument){
      props.pdfDoc(pdfDocument.numPages);
    }
    
    // return pdfNum;
  };

  // console.log(props.pdf);
  return (
    <div>
      {!pdfDocument && <span>Loading...</span>}
      <canvas ref={canvasRef} />
      {/* {Boolean(pdfDocument && pdfDocument.numPages) && (
        <nav>
          <ul className="pager">
            <li className="previous">
              <button disabled={page === 1} onClick={() => setPage(page - 1)}>
                Previous
              </button>
            </li>
            <li className="next">
              <button
                disabled={page === pdfDocument.numPages}
                onClick={() => setPage(page + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      )} */}
    </div>
  );
};

export default MyPdfViewer;
// ReactDOM.render(<MyPdfViewer />, document.getElementById('root'));