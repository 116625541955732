// import React, { Component } from 'react';
// import {Button, Col, Container, Form, Media, Row} from 'react-bootstrap';
// import imagePath from '../src/assets/images/temp/image1.png';
// import imagePath2 from '../src/assets/images/temp/image2.png';

// class versionHistory extends Component {

//   render() {
//     return (
//       <React.Fragment>
//         <div className="section-title">
//           <Button variant="outline-light" className="custom-link">
//             <span className="icon-back" /> Version 3 notes
//           </Button>
//         </div>
//         <Container className="card-list-notes">
//           <Row>
//             <Col>
//               <Media>
//                 <img
//                   width={64}
//                   height={64}
//                   className="mr-3"
//                   src={imagePath2}
//                   alt="user"
//                 />
//                 <Media.Body>
//                   <Row>
//                     <Col sm={12}>
//                       <Form.Group>
//                         <Form.Control
//                           as="textarea"
//                           rows="3"
//                           placeholder="Add a new note"
//                         />
//                       </Form.Group>
//                     </Col>
//                   </Row>
//                 </Media.Body>
//               </Media>
//             </Col>
//           </Row>
//           <Row>
//             <Col>
//               <Media>
//                 <img
//                   width={64}
//                   height={64}
//                   className="mr-3"
//                   src={imagePath}
//                   alt="user"
//                 />
//                 <Media.Body>
//                   <Row>
//                     <Col sm={10}>
//                       <h5>Asmita Aswami</h5>
//                       <p>
//                         Lorem ipsum dolor sit amet, consectetur adipiscing elit,
//                         sed do eiusmod tempor incididunt ut labore et dolore magna
//                         aliqua. Ut enim ad minim veniam, quis nostrud exercitation
//                         ullamco laboris
//                       </p>
//                     </Col>
//                     <Col sm={2}>
//                       <div className="date-time">11:34 pm</div>
//                     </Col>
//                   </Row>
//                 </Media.Body>
//               </Media>
//             </Col>
//           </Row>
//           <Row>
//             <Col>
//               <Media>
//                 <img
//                   width={64}
//                   height={64}
//                   className="mr-3"
//                   src={imagePath}
//                   alt="user"
//                 />
//                 <Media.Body>
//                   <Row>
//                     <Col sm={10}>
//                       <h5>Sual Raychaudhari</h5>
//                       <p>
//                         Lorem ipsum dolor sit amet, consectetur adipiscing elit,
//                         sed do eiusmod tempor incididunt ut labore et dolore magna
//                         aliqua. Ut enim ad minim veniam, quis nostrud exercitation
//                         ullamco laboris
//                       </p>
//                     </Col>
//                     <Col sm={2}>
//                       <div className="date-time">2 days ago</div>
//                     </Col>
//                   </Row>
//                 </Media.Body>
//               </Media>
//             </Col>
//           </Row>
//         </Container>
//       </React.Fragment>
//     )
//   }
// }

// export default versionHistory;

import React, { Component } from 'react';
import {Button, Col, Container, Media, Row} from 'react-bootstrap';
import imagePath from '../src/assets/images/temp/Aakash.jpg';

class versionHistory extends Component {

constructor(props) {
super(props);

this.state = {
nowFileCreatedBy:this.props.createdBy,
nowSelectedVersion:props.selectedVersion,
comment:"no comments",
comments:[]

}
}

componentWillReceiveProps(props){
this.setState({
comments:props.comment
},()=>console.log(this.state.comments))
}

render() {




if(this.props.selectedVersion==="none" ){
return(
<React.Fragment>
<h5>Please click on version to see its notes
</h5>
</React.Fragment>
)
}

if(this.props.comment===undefined){
return(
<React.Fragment>
{/* <h5>No notes asscoiated to this version
</h5> */}
</React.Fragment>
)
}


return (
<React.Fragment>
<div className="section-title">
<Button variant="outline-light" className="custom-link">
<span className="icon-back" /> Version {this.props.selectedVersion} notes
</Button>
</div>
<Container className="card-list-notes">
{
this.state.comments.map((item, index) =>
<Row key={index}>
<Col>
<Media>
<img
width={64}
height={64}
className="mr-3"
src={imagePath}
alt="user"
/>
<Media.Body>
<Row>
<Col sm={10}>
<h5>{this.state.nowFileCreatedBy}</h5>
<p>
{item.comment}
</p>
</Col>
<Col sm={2}>
<div className="date-time">{item.time}</div>
</Col>
</Row>
</Media.Body>
</Media>
</Col>
</Row>)
}

</Container>
</React.Fragment>
)
}
}

export default versionHistory;