import React, { Component } from 'react'
import Picky from "react-picky";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button } from 'react-bootstrap';
import Axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';


export default class PlaceholderSigner extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dropdownlistforsigner: [],
            selectedSigner: [],
            instruction: '',
            initialInstruction: 'First of all,Please Select the Signer then Proceed with the Placeholder'
        }

        this.handleChange = this.handleChange.bind(this)
        this.selectMultipleOption = this.selectMultipleOption.bind(this)
        this.selectplaceholder=this.selectplaceholder.bind(this)
    }

    handleChange(event) {
        this.setState({
            value: event.target.value,
            [event.target.name]: event.target.value,

        });

    }


    selectMultipleOption(value) {
        console.count('onChange')
        console.log("Val", value);
        this.props.triggerPlaceholder(value)
        this.setState({
            selectedSigner: value,
            initialInstruction: '',
            instruction: 'Before Invite Signer Please Select Placeholder Which is at Left Bottom Corner '
        });

    }
    componentDidMount() {
        // fetch(`${process.env.REACT_APP_API_URL}selectowaFileSigner?id=` + this.props.id)
        //     .then(response => response.json())
        //     .then(response => this.setState({ NoofSignerforDocument: response.data }, () =>
        //         this.state.NoofSignerforDocument.map((item) => this.state.dropdownlistforsigner.push(item.mailID))
        //     ))
        //     .catch(err => console.error(err))

        Axios.get(process.env.REACT_APP_API_URL+ 'getDocumentDetails?fileId=' + this.props.id + '&type=Signer' + '&signed='+ true).then(response => 
            {console.log(response.data);
            this.setState({
                 NoofSignerforDocument: response.data.files }, () =>
                this.state.NoofSignerforDocument.map((item) => this.state.dropdownlistforsigner.push(item.User.email))
            )}
        ).catch(err => {
            console.log(err);
        })

    }

    selectplaceholder = async () => {
        this.setState({
            instruction: ''
        })

        let sendy = 297 / document.getElementById('pdff').children[0].height *this.state.sy;
        let sendx = 210 / document.getElementById('pdff').children[0].width * this.state.sx;

     console.log(sendx + ' ' + sendy)
        console.log('array of placeholder', this.props.pageWithPlaceholderLocation);
        console.log("mail sent to signer" + this.state.selectedSigner)
        console.log("mail sent to signer xcord---" + this.props.x_cord)
        console.log("mail sent to signer ycord---" + this.props.y_cord)
        console.log("mail sent to signer pageNo---" + this.props.pageNo)
        console.log("mail sent to signer document---" + this.props.id)
        console.log("mail sent to signer hash---" + this.props.hash)
        console.log("mail sent to signer actual x-cord---" + this.props.actualxcord)
        console.log("mail sent to signer actual y-cord---" + this.props.actualycord)

        let full_name;

       await  fetch(`${process.env.REACT_APP_API_URL}checkfornameusingemail?email=` + this.state.selectedSigner)
            .then(response => response.json())
            .then(response => this.setState({ findnameusingemail: response.data }, async () => {
                await this.state.findnameusingemail.map((name) => { 
                    full_name = name.first_name + " " + name.last_name
                })
            }
            ))
            .catch(err => console.error(err))

        console.log(full_name)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({pageWithPlaceholderLocation: this.props.pageWithPlaceholderLocation})
        };
      await   fetch(`${process.env.REACT_APP_API_URL}updatesignertable?id=` + this.props.id+'&email='+this.state.selectedSigner +'&y_cord=' + this.props.y_cord+'&x_cord='+this.props.x_cord+'&actualx='+this.props.actualxcord+'&actualy='+this.props.actualycord , requestOptions)
        .then(response => { 
            // response.json();
            toast.success('Successfully Placed')
            
        })
        .then(response =>  this.setState({ updatesigner: response.data }, () => { 
           
            

        }))
        .catch(err => console.error(err))   

         fetch(`${process.env.REACT_APP_API_URL}placeholderemail?email=` + this.state.selectedSigner+'&pageNo='+this.props.pageNo + '&id=' + this.props.id + '&url=' + this.props.hash + '&full_name=' + full_name + '&y_cord=' + this.props.y_cord+'&x_cord='+this.props.x_cord)
            .then(response => response.json())
            .then(response => this.setState({ Signeremail: response.data }))
            .catch(err => console.error(err))

            // window.location.reload();

    }
    render() {

        const enabled = this.state.selectedSigner.length > 0 && this.props.x_cord != null && this.props.y_cord != null
        return (
            <div>
                     <ToastContainer />
                <h5 style={{ color: "green" }}>{this.state.initialInstruction}</h5>
                <ListGroup>
                    <h5 style={{ fontFamily: "monospace" }}><strong>Signers</strong></h5>
                    <Picky
                        value={this.state.selectedSigner}
                        options={this.state.dropdownlistforsigner}
                        onChange={this.selectMultipleOption}
                        open={false}
                        valueKey="id"
                        labelKey="name"
                        includeSelectAll={true}
                        includeFilter={true}
                        dropdownHeight={500}
                        placeholder="- - - Please Select - - -"
                    />
                </ListGroup>
                <ListGroup>
                    <Button  color="primary" style={{ width: "100%", height: "43px" }} className="m-2" disabled={!enabled} onClick={this.selectplaceholder}>Invite Signer</Button>
                </ListGroup>
                <h4 style={{ color: "red" }}>{this.state.instruction}</h4>
            </div>
        )
    }
}
