import React, { Component } from "react";
import checklist from "./assets/html/images/checklist.svg";
import "./assets/html/css/main.css";
import "./assets/html/css/hm-style.css";
import "./assets/html/css/color_skins.css";
import { withRouter } from "react-router-dom";
//import { Button } from 'react-bootstrap';
import ipfsClient from "ipfs-http-client";
import Modal from "react-responsive-modal";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import toastr from "reactjs-toastr";
import { Card, Container, Col, Row, Button, Table } from "react-bootstrap";
import Header from "./components/Header";
import { ToastContainer, toast } from 'react-toastify';    
import 'react-toastify/dist/ReactToastify.css';

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      categoryName: '',
      categoryValue: ''
    };

    this.saveCategory = this.saveCategory.bind(this);
  }

  getCategories() {
    Axios.get(process.env.REACT_APP_API_URL + "getAllCategories").then(
      (categories) => {
        this.setState({
          categories: categories.data,
        });
      }
    );
  }

  saveCategory() {

    

    const data = {
      companyId: sessionStorage.getItem('companyId'),
      categoryName: this.state.categoryName,
      value: this.state.categoryValue
    }

    Axios.post(process.env.REACT_APP_API_URL + "createCategory",data ).then(category => {
      // toastr.success('Successfully Created Category');
      toast.success('Successfully Created Category');
      this.getCategories();
    }).catch(err =>{
      console.log(err)
    })
  }

  componentDidMount() {
    this.getCategories();
  }


  setInputValue(value) {
    console.log(value);
    this.setState({categoryName: value})
  }

  setInputCategoryValue(value) {
    console.log(value);
    this.setState({categoryValue: value})
  }
  render() {
    // if (this.state.loggedIn === false) {
    //     return <Redirect to="/" />
    //   }
    return (
      <div className="App">
           <ToastContainer />
        
        <Header />
        <Container fluid>
          <Row>
            <h2 className="pl-5 pt-3">Add Categories</h2>
          </Row>
          <Card style={{ minHeight: "400px" }}>
            <Row style={{ "padding-top": "50px" }}>
              <Col sm="6" className="pl-5">
                <div class="form-group">
                  <label for="">Category Name</label>
                  <input
                    type="text"
                    class="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder=""
                    onChange={(e) => this.setInputValue(e.target.value)}
                  />
                  <small id="helpId" class="form-text text-muted">
                    Help text
                  </small>
                </div>
              </Col>
              <Col sm="6" className="pr-5">
                <div class="form-group">
                  <label for="">Category Value</label>
                  <input
                    type="text"
                    class="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder=""
                    onChange={(e) => this.setInputCategoryValue(e.target.value)}
                  />
                  <small id="helpId" class="form-text text-muted">
                    Help text
                  </small>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="9"></Col>
              <Col sm="3">
                <Button className="float-right mr-4" variant="primary" onClick={() => this.saveCategory()}>
                  Save
                </Button>{" "}
              </Col>
            </Row>
            <Row className="pt-4">
              <Container fluid className="px-4">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Category Name</th>
                      <th>Category Value </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.categories.map((cat, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{cat.cateoryName}</td>
                        <td>{cat.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Container>
            </Row>
          </Card>
        </Container>
      </div>
    );
  }
}

export default withRouter(Category);
