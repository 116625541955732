
import React from 'react';
import './style.css';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import logo from '../assets/images/logo.png';


class Register extends React.Component {
    constructor() {
        super();
        this.state = {
            fields: {},
            errors: {},
            statusofregister: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);

    };

    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });

    }

    submituserRegistrationForm(e) {
        e.preventDefault();
        if (this.validateForm()) {
            let fields = {};
            fields["username"] = "";
            fields["username1"] = "";
            fields["emailid"] = "";
            fields["mobileno"] = "";
            fields["password"] = "";
            this.setState({
                fields: fields
            });
            console.log(this.state.fields.username)
            alert("register successfully!!!");
            fetch(`${process.env.REACT_APP_API_URL}createSuperUser`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "business_name": this.state.companyName,
                    "firstName": this.state.fields.username,
                    "lastName": this.state.fields.username1,
                    "email": this.state.fields.emailid,
                    "password": this.state.fields.password,
                    "phone_no":this.state.fields.mobileNo,
                    "description": this.state.description,
                    "domain": this.state.domain
                }),
            })
                .then(response => response.json())
                .then(response => this.setState({ responseFromRegisterUser: response.data }))
                .catch(err => console.error(err));

        }

    }



    validateForm() {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["username"]) {
            formIsValid = false;
            errors["username"] = "*Please enter your username.";
        }

        if (typeof fields["username"] !== "undefined") {
            if (!fields["username"].match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["username"] = "*Please enter alphabet characters only.";
            }
        }
        if (!fields["username1"]) {
            formIsValid = false;
            errors["username1"] = "*Please enter your username.";
        }

        if (typeof fields["username1"] !== "undefined") {
            if (!fields["username1"].match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["username1"] = "*Please enter alphabet characters only.";
            }
        }

        if (!fields["emailid"]) {
            formIsValid = false;
            errors["emailid"] = "*Please enter your email-ID.";
        }

        if (typeof fields["emailid"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["emailid"])) {
                formIsValid = false;
                errors["emailid"] = "*Please enter valid email-ID.";
            }
        }

        if (!fields["mobileno"]) {
            formIsValid = false;
            errors["mobileno"] = "*Please enter your mobile no.";
        }

        if (typeof fields["mobileno"] !== "undefined") {
            if (!fields["mobileno"].match(/^[0-9]{10}$/)) {
                formIsValid = false;
                errors["mobileno"] = "*Please enter valid mobile no.";
            }
        }

        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "*Please enter your password.";
        }

        if (typeof fields["password"] !== "undefined") {
            // if (!fields["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
            //   formIsValid = false;
            //   errors["password"] = "*Please enter secure and strong password.";
            // }
        }

        this.setState({
            errors: errors
        });
        return formIsValid;


    }

    gotologin = () => {
        this.props.history.push({
            pathname: "/",
            state: {


            }
        })
    }


    render() {
        return (
            <div className="App">
                <Container className="login" fluid>
                    <Row>
                        <Col className="login__left-panel">
                            <Container fluid className="login__left-panel--image">
                                <Image className="app-logo__image" src={logo} alt="Versa Logo" />
                            </Container>
                        </Col>
                        <Col className="login__right-panel">


                            <div id="main-registration-container">
                                <div id="register">
                                    <h3>Registration page</h3>
                                    <form method="post" name="userRegistrationForm" onSubmit={this.submituserRegistrationForm} >
                                    <label>Company Name</label>
                                        <input type="text" name="username" value={this.state.fields.companyName} onChange={this.handleChange} />
                                        <label>FirstName</label>
                                        <input type="text" name="username" value={this.state.fields.username} onChange={this.handleChange} />
                                        <div className="errorMsg">{this.state.errors.username}</div>
                                        <label>LastName</label>
                                        <input type="text" name="username1" value={this.state.fields.username1} onChange={this.handleChange} />
                                        <div className="errorMsg">{this.state.errors.username1}</div>
                                        <label>Email ID:</label>
                                        <input type="text" name="emailid" value={this.state.fields.emailid} onChange={this.handleChange} />
                                        <div className="errorMsg">{this.state.errors.emailid}</div>
                                        <label>Mobile No:</label>
                                        <input type="text" name="mobileno" value={this.state.fields.mobileno} onChange={this.handleChange} />
                                        <div className="errorMsg">{this.state.errors.mobileno}</div>
                                        <label>Password</label>
                                        <input type="password" name="password" value={this.state.fields.password} onChange={this.handleChange} />
                                        <div className="errorMsg">{this.state.errors.password}</div>
                                        <Button variant="primary" type="submit" style={{ width: "515px", height: "43px" }} className="button1">
                                            <span style={{ fontSize: "14px", fontStyle: "bold" }}>Register</span>
                                        </Button>

                                    </form>
                                    <Button variant="primary" onClick={this.gotologin} type="submit" style={{ width: "515px", height: "43px" }} className="button2">
                                        <span style={{ fontSize: "14px", fontStyle: "bold" }} >Login</span>
                                    </Button>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>

        );
    }


}


export default Register;