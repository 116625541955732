import React, { Component } from "react";
import checklist from "./assets/html/images/checklist.svg";
import "./assets/html/css/main.css";
import "./assets/html/css/hm-style.css";
import "./assets/html/css/color_skins.css";
import { withRouter } from "react-router-dom";
//import { Button } from 'react-bootstrap';
import ipfsClient from "ipfs-http-client";
import Modal from "react-responsive-modal";
import Picky from "react-picky";
// import { ToastContainer } from "react-toastr";
import { ToastContainer, toast } from 'react-toastify';    
import "react-picky/dist/picky.css";
import { Container } from "react-bootstrap";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Spinner,
} from "reactstrap";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import toastr from "reactjs-toastr";
import { FileDrop } from "react-file-drop";
import { Table } from "react-bootstrap";

const ipfs = new ipfsClient({
  host: process.env.REACT_APP_HOST,
  port:  process.env.REACT_APP_PORT,
  protocol:  process.env.REACT_APP_PROTOCOL,
});
let container;



// const ipfs = new ipfsClient({
//   host: "127.0.0.1",
//   port: "5001",
//   protocol: "http",
// });

Axios.interceptors.response.use(function (config) {
  console.log(config);
  const token = sessionStorage.getItem("token");
  config.headers.Authorization = "Bearer " + token;

  return config;
});
console.log(ipfs);
require("dotenv").config();

class Dashboard extends Component {
  constructor(props) {
    super(props);

    const token = sessionStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      openModal: false,
      openBatchModal: false,
      fileUp: "",
      comment: "no comment",
      selectMenu: "",
      resObj: null,
      apikey: "XVYgHU0qTHRF6nLIzrAW4Zthd2P4fn",
      loggedIn,
      fileInputRef: null,
      uploadButton: false,
      batchList: [],
      selectedCategoryId: [],
      signers: [],
      awaitingSign: [],
      signedDoc: [],
      template: [],
      batchPage2: false,
      batchPage3: false,
      isUploading: false,
      categories: [],
      uploadedList: [],

      selectedEmpSigner: [],
      selectedEmpTemplate: [],
      selectedCategory: [],
      selectedCandTemplate: [],
      selectedCandSigner: []
    };
    this.inputRef = React.createRef();
    this.completedDocs = this.completedDocs.bind(this);
    this.AwaitingDocs = this.AwaitingDocs.bind(this);
    this.awaitingOthers = this.awaitingOthers.bind(this);
    this.dueDocs = this.dueDocs.bind(this);
    this.checkAllDocuments = this.checkAllDocuments.bind(this);
    this.addToIpfs = this.addToIpfs.bind(this);
    this.isCandidateBatch = this.isCandidateBatch.bind(this);
  };

  componentDidMount() {
    this.getCategories();
    this.getTemplates();
    this.getUsers();
    console.log(process.env.REACT_APP_HOST);
    console.log(process.env.REACT_APP_PORT);

    console.log(process.env.REACT_APP_PROTOCOL);

  }


  getTemplates() {
    Axios.get(process.env.REACT_APP_API_URL + 'templates').then(templates => {
      this.setState({
        template: templates.data
      })
    }).catch(err => {
      alert(err);
    })

  }



  isCandidateBatch() {
    toast.success('Successfully Added Employee');
    this.setState({
      batchPage2: false,
      batchPage3: true,
    })
  }

  selectEmpTemplate = (e, index) => {
    console.log(e);

    this.setState({
      selectedEmpTemplate: e
    })
  }

  selectEmpSigner = (e) => {

    console.log(e);
    this.setState({
      selectedEmpSigner: e
    })

    console.log(this.state.selectedEmpSigner);
  }

  selectCategory = (e, index) => {
    console.log(e);

    this.setState({
      selectedCategory: e
    })
    console.log(this.state.selectedCategory)
  }

  selectCandTemplate = (e) => {
    console.log(e);

    this.setState({
      selectedCandTemplate:  e
    })
  }

  selectCandSigner = (e, index) => {
    console.log(e);

    this.setState({
      selectedCandSigner: { ...this.state.selectedCandSigner, [index]: e }
    })
  }

  getUsers() {

    Axios.get(process.env.REACT_APP_API_URL + 'getUser?company=true&companyId=' + sessionStorage.getItem('companyId')).then(users => {
      this.setState({
        signers: users.data
      })
    }).catch(err => {
      console.log(err);
    })

  }

  completedDocs = () => {
    this.props.history.replace("/completeddocs");
  };

  AwaitingDocs() {
    this.props.history.replace("/awaitingsigneddocs");
  }

  awaitingOthers() {
    this.props.history.replace("/awaitingOthers");
  }


  onCategorySelect = (event) => {
    console.log(event.target.value);
    this.setState({ selectedCategoryId: event.target.value })
  }

  getCategories() {
    Axios.get(process.env.REACT_APP_API_URL + "getAllCategories").then(
      (categories) => {
        this.setState({
          categories: categories.data,
        });
      }
    );
  }

  dueDocs() {
    alert("inside due");
  }

  checkAllDocuments() {
    this.props.history.replace("/documents");
  }

  onOpenModal = () => {
    this.setState({
      openModal: true,
    });
  };

  onOpenBatchModal = () => {
    this.setState({
      openBatchModal: true,
    });
  };

  batchUpload = (event) => {

    console.log(this.inputRef.current.files);
    console.log(this.inputRef.current.files.length);
    console.log('sa', event.file);



    var batchList = [];
    if (this.inputRef.current.files.length > 0) {
      for (var file = 0; file < this.inputRef.current.files.length; file++) {
        batchList.push({
          fileName: this.inputRef.current.files[file].name,
          event: this.inputRef.current.files[file]
        });

      }
      this.setState({
        batchList: batchList,
      });
      this.setState({
        uploadButton: true,
      });
    } else {
      if (event !== undefined && event !== null) {
        this.setState({
          uploadButton: true,
        });
      } else if (event.target.files > 0) {
        this.setState({
          uploadButton: true,
        });
      }
      if (event[0] !== undefined) {
        for (var file = 0; file < event.length; file++) {
          batchList.push({ fileName: event[file].name, event: event[file] });
        }

        this.setState({
          batchList: batchList,
        });
      } else {
        for (var file = 0; file < event.target.files.length; file++) {
          batchList.push({
            fileName: event.target.files[file].name,
            event: event[file],
          });
        }

        this.setState({
          batchList: batchList,
        });
      }
    }
  };







  uploadBatchFiles = async () => {
    //   Upload files from this.state.batchList.event all files will be here in array append them into form data
    // console.log('Hellow');
    var uploadedList = [];
    for (var file = 0; file < this.state.batchList.length; file++) {
      console.log(this.state.batchList[file]);
      const fileAdded = await ipfs.add({
        content: this.state.batchList[file].event,
        wrapWithDirectory: true,
      });
      console.log("Hash: " + fileAdded[0].hash);
      uploadedList.push({
        fileName: this.state.batchList[file].fileName,
        hashKey: fileAdded[0].hash,
      });
    }
    this.setState({
      uploadedList: uploadedList,
      batchPage2: true,
    });
    console.log('uploadedLIst', this.state.uploadedList);
  }

  onBatchCloseModal = () => {
    this.setState({ openBatchModal: false });
  };

  onFileChange = (e) => {
    this.setState(
      {
        fileUp: e.target.files[0],
      },
      () => console.log(this.state.fileUp)
    );
  };
  handleChangeComment = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => console.log(this.state.comment)
    );
  };
  onCloseModal = () => {
    this.setState({ openModal: false });
  };

  inputFileSimulation = (e) => {
    console.log('input simuatlation', e);
    this.inputRef.current.click();

    console.log(this.inputRef.current.files);
  };

  //  getDocuments() {
  //       Axios.get(process.env.REACT_APP_API_URL+ 'getCountOfDocuments?token='+token)
  //           .then(response => this.setState({ 
  //               alldocuments: response.data.fileCreatedCount,
  //               awaitingCount: response.data.awaitingCount,
  //               awaitingOthersCount: response.data.awaitingOthersCount
  //            }))
  //           .catch(err => console.error(err))
  //   }




  addToIpfs = async (file, comment, dctype, apikey) => {
    await this.setState({ isUploading: true });
    let resp;
    let resObj;

    const fileAdded = await ipfs.add({
      content: file,
      wrapWithDirectory: true,
    });
    console.log("Hash: " + fileAdded[0].hash);
    alert("File Successfully Added: " + fileAdded[0].hash);
    // alert("file Name : "+file.name);
    // alert("Comment: " + comment);
    await this.setState({ isUploading: false });
    var bodyFormData = new FormData();
    bodyFormData.set("name", file.name);
    bodyFormData.append("file", file);
    bodyFormData.append("rename_if_exists", true);

    const CreatedBy = sessionStorage.getItem("token");
    const no = function randomNumber(min = 1, max = 3042034223) {
      return Math.random() * (max - min) + min;
    };



    Axios.post(
      process.env.REACT_APP_API_URL +
      `add?fileName=` +
      file.name +
      "&hash=" +
      fileAdded[0].hash +
      "&category=" +
      dctype +
      "&description=" +
      comment +
      "&companyId=" +
      sessionStorage.getItem("companyId") +
      "&categoryId=" + this.state.selectedCategoryId
    )
      //   .then(response => response.json())
      .then((response) => {
        resp = response.data;
        console.log(resp);
        this.props.update();
        // TODO add working toaster and call getdocumentCount API Again.
        toastr.success("Successfully Uploaded", "Title", {
          displayDuration: 3000,
        });
      })
      .catch((err) => console.error(err));

    // await fetch(`${process.env.REACT_APP_API_URL}addComment?ParentID=` + resp.insertId + '&version=' + 1 + '&comment=' + comment)
    //   .catch(err => console.error(err))

    //    await window.location.reload();
  };



  saveBatch = () => {
    var dataEmpArr = [];
    console.log(this.state.selectedEmpTemplate);
    for (var i = 0; i < this.state.uploadedList.length; i++) {
      const dataEmp = {
        fileName: this.state.uploadedList[i].fileName,
        hash: this.state.uploadedList[i].hashKey,
        userId: this.state.selectedEmpSigner.id,
        type: 'Signer',
        pageWithPlaceholderLocation: this.state.selectedEmpTemplate.TemplatePlaceHolders,
        categoryId: this.state.selectedCategory.id,
        companyId: sessionStorage.getItem('companyId')
      }
      dataEmpArr.push(dataEmp);
    }
    var dataCandArr = []
    for(var i=0; i < this.state.uploadedList.length; i++) {
      const dataCand = {
        fileName: this.state.uploadedList[i].fileName,
        hash:this.state.uploadedList[i].hashKey,
        userId: this.state.selectedCandSigner[i].id,
        templateId: this.state.selectedCandTemplate.id,
        pageWithPlaceholderLocation: this.state.selectedCandTemplate.TemplatePlaceHolders,
        type: 'Signer',
        category: this.state.selectedCategory,
        companyId: sessionStorage.getItem('companyId')

      }
      dataCandArr.push(dataCand);
    }
    Axios.post(
      process.env.REACT_APP_API_URL + 'addWithBatch', { data: dataEmpArr, data2: dataCandArr })
      //   .then(response => response.json())
      .then((response) => {
        toast.success('Successfully Added Candidates and Batch Completed');
        // resp = response.data;
        console.log(response.data);
        this.onBatchCloseModal();
        // console.log(resp);
        // toastr.success("Successfully Uploaded", "Title", {
        //   displayDuration: 3000,
        // });
      })
      .catch((err) => console.error(err));


   

    //   Axios.post(
    //     process.env.REACT_APP_API_URL + 'addWithBatch', dataCand)
    //     //   .then(response => response.json())
    //     .then((response) => {
    //       // resp = response.data;
    //       // console.log(resp);
    //       toastr.success("Successfully Uploaded", "Title", {
    //         displayDuration: 3000,
    //       });
    //     })
    //     .catch((err) => console.error(err));
    // }


  }



  render() {
    if (this.state.loggedIn === false) {
      return <Redirect to="/" />;
    }
    const containerStyle = {

      // "overflow-x": "scroll"
    }
    const { openModal } = this.state;
    const { openBatchModal } = this.state;
    const styles = {
      border: "1px solid blue",
      "border-radius": "15px",
      width: "100%",
      "margin-bottom": "15px"
    };
    return (
      <div className="left-panel data-content">
      
        <div>
          <div className="overlay" />
          <section>
            <div className="container-fluid">
            <ToastContainer />
              <div className="row clearfix">
                <div className="col-lg-12">
                  <div className="card">
                    <h4>My Action</h4>
                    <div className="container-fluid">
                      <div className="row clearfix social-widget">
                        <div className="col-lg-3 col-md-3">
                          <div className="card info-box-2 hover-zoom-effect instagram-widget">
                            <div className="icon">
                              <i className="zmdi zmdi-border-color" />
                            </div>
                            <div
                              className="content"
                              style={{ cursor: "pointer" }}
                              onClick={this.AwaitingDocs}
                            >
                              <div className="text">Awaiting My Signature</div>
                              <div className="number">
                                {/* {this.props.awaitingCount.map((item) => ( */}
                                <div>
                                  {" "}
                                  <h6>{this.props.awaitingCount}</h6>
                                </div>
                                {/* ))} */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <div className="card info-box-2 hover-zoom-effect linkedin-widget">
                            <div className="icon">
                              <i className="zmdi zmdi-account-circle" />
                            </div>
                            <div
                              className="content"
                              style={{ cursor: "pointer" }}
                              onClick={this.awaitingOthers}
                            >
                              <div className="text">Awaiting Others</div>
                              <div className="number">
                                {" "}
                                <div>
                                  {" "}
                                  <h6>{this.props.awaitingOthersCount}</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3" onClick={this.check}>
                          <div className="card info-box-2 hover-zoom-effect behance-widget">
                            <div className="icon">
                              <i className="zmdi zmdi-cloud-done" />
                            </div>
                            <div
                              className="content"
                              style={{ cursor: "pointer" }}
                              onClick={this.completedDocs}
                            >
                              <div className="text">Completed</div>
                              <div className="number">
                                {/* {this.props.signedCount.map((item) => (
                                                                    <div as="div" key={item}> <h6>{item.count}</h6></div>
                                                                ))} */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <div className="card info-box-2 hover-zoom-effect behance-widget">
                            <div className="icon">
                              <i className="zmdi zmdi-time-countdown" />
                            </div>
                            <div
                              className="content"
                              onClick={this.dueDocs}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="text"> Due Soon</div>
                              <div className="number">0</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row clearfix">
                <div className="col-lg-8 col-md-12">
                  <div className="row clearfix">
                    <div className="col-lg-7 col-md-6">
                      <div className="card tasks_report">
                        <div className="">
                          <h2>
                            <strong>File</strong> Upload
                          </h2>
                        </div>
                        <div className="card">
                          <div className="body" style={{ padding: "2px" }}>
                            {/* <form action="/" id="frmFileUpload" className="dropzone" method="post" encType="multipart/form-data"> */}
                            <div className="dz-message">
                              <div className="drag-icon-cph">
                                {" "}
                                <i className="material-icons">touch_app</i>{" "}
                              </div>
                              <h3>Drop files here or click to upload.</h3>
                              <em>
                                Please Upload your <strong>Documents</strong>
                              </em>{" "}
                            </div>
                            <div className="fallback">
                              <button
                                className="btn-success"
                                onClick={this.onOpenModal}
                              >
                                Upload
                              </button>
                              <button
                                className="btn-success ml-3"
                                onClick={this.onOpenBatchModal}
                              >
                                Batch
                              </button>
                            </div>

                            {/* </form> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Modal
                      as="div"
                      open={openModal}
                      onClose={this.onCloseModal}
                      center
                    >
                      <h2>Add New File</h2>
                      <hr />
                      <Form as="div">
                        <FormGroup as="div">
                          <Input
                            type="file"
                            id="fileUp"
                            name="fileUp"
                            onChange={this.onFileChange}
                          />
                        </FormGroup>
                        <FormGroup as="div">
                          <Label for="exampleSelect">Document Category :</Label>
                          <Input
                            type="select"
                            name="select"
                            id="exampleSelect"
                            onChange={this.onCategorySelect}
                          >
                            <option selected value="">All Categories</option>
                            {this.state.categories.map((tile, i) => (
                              <option key={i} value={tile.id} > {tile.cateoryName}</option>
                            ))}
                            {/* <option>- - - Please Select - - -</option>
                            <option>Agreement Letter</option>
                            <option>Contract</option>
                            <option>Employee Offer letter</option>
                            <option>Employee on boarding</option>
                            <option>Evaluation Forms</option>
                            <option>Insurance Forms</option>
                            <option>Invoice</option>
                            <option>Non-disclosure Agreement</option>
                            <option>Proposal</option>
                            <option>Purchase Order</option>
                            <option>Seperation Agreement</option>
                            <option>Statement of work</option>
                            <option>work Order</option>
                            <option>Other</option> */}
                          </Input>
                        </FormGroup>
                        <FormGroup as="div">
                          <Input
                            type="textarea"
                            name="comment"
                            placeholder="Add a note for this version..."
                            cols="500"
                            rows="10"
                            onChange={this.handleChangeComment}
                          />
                        </FormGroup>

                        <FormGroup as="div">
                          <Col className="text-right">
                            <Button
                              name="submitbtn"
                              className="btn btn-primary"
                              onClick={() =>
                                this.addToIpfs(
                                  this.state.fileUp,
                                  this.state.comment,
                                  this.state.selectMenu,
                                  this.state.apikey
                                )
                              }
                            >
                              {this.state.isUploading && (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </>
                              )}
                              Add
                            </Button>
                          </Col>
                        </FormGroup>
                      </Form>
                    </Modal>
                    <Modal
                      as="div"
                      open={openBatchModal}
                      onClose={this.onBatchCloseModal}
                      center
                      classNames={{
                        modal: 'customModal'
                      }}
                    >
                      <h2>Add Batch</h2>
                      <hr />
                      {!this.state.batchPage2 && !this.state.batchPage3 && (
                        <Container fluid>
                          <Form as="div">
                            <FormGroup as="div">
                              <div style={styles} onClick={this.inputFileSimulation}>
                                <FileDrop
                                  onDrop={(event) => this.batchUpload(event)}
                                // onTargetClick={(event) =>
                                //   this.inputFileSimulation(event)
                                // }
                                //   onTargetClick={(event) => this.batchUpload(event)}
                                >
                                  <input
                                    onChange={(e) => this.batchUpload(e)}
                                    ref={this.inputRef}
                                    type="file"
                                    multiple={true}
                                    className="hidden"
                                  />
                                  Drop some files here!
                                </FileDrop>
                              </div>

                              <div className="row">
                                <Table striped bordered hover>
                                  <thead>
                                    <tr>
                                      <th>Id</th>

                                      <th>File Name</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.batchList.map((tile, i) => (
                                      <tr>
                                        <td>{i + 1}</td>
                                        <td>{tile.fileName}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>

                              <div className="row">
                                {this.state.uploadButton && (
                                  <div className="pt-3">
                                    <div className="form-group float-right">
                                      <button
                                        type="button"
                                        className="btn btn-outline"
                                        onClick={(e) => {
                                          this.uploadBatchFiles();
                                        }}
                                      >
                                        Upload Files
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </FormGroup>
                          </Form>
                        </Container>
                      )}
                      {this.state.batchPage2 && (
                        <Container fluid style={containerStyle}>
                          <Form>
                            <h4>
                              {" "}
                              Add Employeer Signee{" "}
                            </h4>
                            <div className="row">
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Id</th>
                                    {/* <th>Hash</th> */}
                                    <th>File Name</th>
                                    <th>Signer (Employeer)
                                      <Picky
                                        // id={}
                                        value={this.state.selectedEmpSigner}
                                        options={this.state.signers}
                                        onChange={(e) => this.selectEmpSigner(e)}
                                        open={false}
                                        valueKey="id"
                                        // name={"picky" + i}
                                        labelKey="email"
                                        // multiple={true}
                                        // includeSelectAll={true}
                                        includeFilter={true}
                                        dropdownHeight={600}
                                        placeholder="- - - Please Select - - -"
                                      />
                                    </th>
                                    <th>Template (Employeer)
                                      <Picky
                                        value={this.state.selectedEmpTemplate}
                                        options={this.state.template}
                                        onChange={(e) => this.selectEmpTemplate(e)}
                                        open={false}
                                        valueKey="id"
                                        labelKey="templateName"
                                        // multiple={true}
                                        // includeSelectAll={true}
                                        includeFilter={true}
                                        dropdownHeight={600}
                                        placeholder="- - - Please Select - - -"
                                      />
                                    </th>
                                    <th>Category
                                      <Picky
                                        value={this.state.selectedCategory}
                                        options={this.state.categories}
                                        onChange={(e) => this.selectCategory(e)}
                                        // open={false}
                                        valueKey="id"
                                        labelKey="cateoryName"
                                        multiple={false}
                                        // includeSelectAll={true}
                                        includeFilter={true}
                                        dropdownHeight={600}
                                        placeholder="- - - Please Select - - -"
                                      />
                                    </th>
                                    {/* <th>Template (Candidate)</th> */}
                                    {/* <th>Signer (Candidate)</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.uploadedList.map((tile, i) => (
                                    <tr>
                                      <td>{i + 1}</td>
                                      {/* <td>{tile.hashKey}</td> */}
                                      <td>{tile.fileName}</td>
                                      <td>
                                        {this.state.selectedEmpSigner.email}

                                      </td>
                                      <td>

                                        {this.state.selectedEmpTemplate.templateName}

                                      </td>

                                      <td>
                                        {this.state.selectedCategory.cateoryName}

                                      </td>

                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                              <div className="row">
                                <div className="float-left pl-3">
                                  <Button className="btn" type="button" onClick={this.isCandidateBatch}>
                                    Next
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </Container>
                      )}
                      {this.state.batchPage3 && (
                        <Container fluid style={containerStyle}>
                          <Form>
                            <h4>
                              {" "}
                              Add Candidate Signee{" "}
                            </h4>
                            <div className="row">
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Id</th>
                                    {/* <th>Hash</th> */}
                                    <th>File Name</th>
                                    <th>Signer (Candidate)
                                    </th>
                                    <th>Template (Candidate)
                                      <Picky
                                        value={this.state.selectedCandTemplate}
                                        options={this.state.template}
                                        onChange={(e) => this.selectCandTemplate(e)}
                                        open={false}
                                        valueKey="id"
                                        labelKey="templateName"
                                        // multiple={true}
                                        // includeSelectAll={true}
                                        includeFilter={true}
                                        dropdownHeight={600}
                                        placeholder="- - - Please Select - - -"
                                      />
                                    </th>


                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.uploadedList.map((tile, i) => (
                                    <tr>
                                      <td>{i + 1}</td>
                                      {/* <td>{tile.hashKey}</td> */}
                                      <td>{tile.fileName}</td>
                                      <td>
                                        {/* {this.state.selectedEmpSigner.email} */}
                                        <Picky
                                      // id={}
                                      value={this.state.selectedCandSigner[i]}
                                      options={this.state.signers}
                                      onChange={(e) => this.selectCandSigner(e,i)}
                                      open={false}
                                      valueKey="id"
                                      // name={"picky" + i}
                                      labelKey="email"
                                      // multiple={true}
                                      // includeSelectAll={true}
                                      includeFilter={true}
                                      dropdownHeight={600}
                                      placeholder="- - - Please Select - - -"
                                    />
                                      </td>
                                      <td>
                                        {this.state.selectedCandTemplate.templateName}

                                      </td>

                                      <td>
                                        {this.state.selectedCategory.cateoryName}

                                      </td>

                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                              <div className="row">
                                <div className="float-left pl-3">
                                  <Button className="btn" type="button" onClick={this.saveBatch}>
                                    Next
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </Container>
                      )}
                    </Modal>

                    <div className="col-lg-5 col-md-6">
                      <div className="card top-report">
                        <div className="body">
                          <h3 className="m-t-0">
                            0{" "}
                            <i className="zmdi zmdi-trending-up float-right" />
                          </h3>
                          <p className="text-muted">New Feedbacks</p>
                          <div className="progress">
                            <div
                              className="progress-bar l-blush"
                              role="progressbar"
                              aria-valuenow={68}
                              aria-valuemin={0}
                              aria-valuemax={100}
                              style={{ width: "68%" }}
                            />
                          </div>
                          <small>Change 15%</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-12"
                  style={{ cursor: "pointer" }}
                  onClick={this.checkAllDocuments}
                >
                  <div className="card weather2 hover-zoom-effect">
                    <div className="city-selected body l-khaki">
                      <div className="row">
                        <div className="col-12">
                          <div className="city">
                            <span /> View All
                          </div>
                          <div className="night">Documents</div>
                        </div>
                        <div className="info col-7">
                          <div className="temp">
                            {/* {this.props.alldocuments.map((item) => ( */}
                            <div as="div" key="{key}">
                              {" "}
                              <h2>{this.props.alldocuments}</h2>
                            </div>
                            {/* ))} */}
                          </div>
                        </div>
                        <div className="icon col-5">
                          <img src={checklist} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(Dashboard);
