import React from 'react';
import { Redirect } from 'react-router-dom'
import { Button, Fade } from 'react-bootstrap';
import ReactTable from "react-table";
import "react-table/react-table.css";
import "../src/assets/scss/_table.scss";
import { withRouter } from 'react-router-dom';
import Axios from 'axios';

class TableData extends React.Component {
  constructor(props) {
    super(props);
    const token = sessionStorage.getItem("token")
    let loggedIn = true
    if (token == null) {
      loggedIn = false
    }
    this.state = {
      posts: [],
      mail: [],
      Versiondetails: [],
      targetId: null,
      mailId: null,
      hashMail: null,
      fileName: null,
      version: null,
      parentID: null,
      UpdatedAt: null,
      loggedIn,
      fileID: ""

    }
    this.routeChange = this.routeChange.bind(this);
  }

  openIPFS(fileHash) {
    // window.location.href = process.env.REACT_APP_IPFS_FILE + 'ipfs/' + fileHash;
    window.open(process.env.REACT_APP_IPFS_FILE + 'ipfs/' + fileHash)
  }
  componentDidMount() {
    const token = sessionStorage.getItem("userId")
    console.log(this.props.categoryId)
    if (this.props.categoryId === '') {
      fetch(`${process.env.REACT_APP_API_URL}getDocument?userId=` + token)
        .then(response => response.json())
        .then(response => this.setState({ posts: response.filter(n => n) }))
        .catch(err => console.error(err))
    } else {
      console.log('categories with category');
      Axios.get(process.env.REACT_APP_API_URL + 'getDocument?categoryId=' + this.props.categoryId).then(categories => {
        this.setState({ posts: categories.data.filter(n => n) })
      }).catch(err => {
        alert(err)
      })

    }

  }

  componentWillReceiveProps(props) {
    console.log(props);
    const token = sessionStorage.getItem("userId")
    console.log(props.categoryId)
    // if(props.cate)
    if (props.categoryId !== '') {
      
      console.log('categories with category');
      Axios.get(process.env.REACT_APP_API_URL + 'getDocument?categoryId=' + props.categoryId).then(categories => {
        this.setState({ posts: categories.data.filter(n => n) })
      }).catch(err => {
        alert(err)
      })
    
  }
  }

  routeChange(e, fileID, fileName, selectMenu, hash, version, updatedAt, parentID, CreatedBy, description, category) {
    sessionStorage.setItem("id", fileID)
    this.props.history.push({
      pathname: "/signature",
      state: {
        fileID: fileID,
        fileName: fileName,
        selectMenu: selectMenu,
        comment: this.props.comment,
        hash: hash,
        version: version,
        UpdatedAt: updatedAt,
        parentID: parentID,
        category: category,
        CreatedBy: CreatedBy,
        description: description

      }
    })
  }


  render() {
    if (this.state.loggedIn === false) {
      return <Redirect to="/" />
    }
    const columns = [
      {
        id: 'col1',
        columns: [
          {
            Header: 'Document Name',
            id: 'sub_col_1a',
            accessor: (d) => {
              return <div >
                {/* <div className="document-title" id={d.id} onClick={(e) => this.reply_click(e, d.id,d.fileName)} style={{ cursor: 'pointer' }} >{d.fileName}</div> */}
                <div className="document-title" id={d.id} onClick={(e) => this.props.triggerChangeFile(d.FileHash.fileName, d.FileHash.id, d.CreatedBy)} style={{ cursor: 'pointer' }} >{d.FileHash.fileName}</div>
                <span onClick={() => this.openIPFS(d.FileHash.hash)} className="document-id"> <span style={{color: "black"}}> Click to view :&nbsp; </span>{d.FileHash.hash}</span>
              </div>
            },
            width: 500
          },
        ]
      },
      {
        id: 'col3',
        columns: [
          {
            Header: '',
            id: 'sub_col_3a',
            accessor: (d) => {
              return <div >
                {/* <button id={d.id} onClick={(e)=>this.routeChange(e,d.id,d.fileName)} >Add Signers</button> */}

                <Button variant="outline-light" title="Assign Signer"
                  id={d.id} onClick={(e) => this.routeChange(e, d.FileHash.id, d.FileHash.fileName, d.category, d.FileHash.hash, d.version, d.updatedAt, d.parentID, d.CreatedBy, d.FileHash.description, d.FileHash.Category.cateoryName)}>
                  <span className="icon-edit"></span>
                </Button>


              </div>
            },
            width: 150
          },
        ]
      },
      {
        Header: "Version",
        accessor: "versionId",
        width: 100

      },

      {
        Header: 'Last Modified',
        accessor: 'updatedAt',
        width: 300

      },

      {
        Header: '',
        accessor: 'moreactionitems',
        width: 50,
        sortable: false,
        className: 'more-action-items-td',
        Cell: row => {
          return <div className="more-action-items-td">

            <Button variant="outline-light" className="custom-icon">
              <span className="icon-three-dot" />
            </Button>
            {/* <Fade>
              <div className="action-links">

              
                <Button variant="outline-light" title="Download">
                  <span className="icon-download"></span>
                </Button>
                <Button variant="outline-light" title="Duplicate">
                  <span className="icon-duplicate"></span>
                </Button>
                <Button variant="outline-light" title="Edit">
                  <span className="icon-edit"></span>
                </Button>
              
              </div>
            </Fade> */}
          </div>
        },
      }

    ]

    return (
      <React.Fragment>
        <ReactTable
          data={this.state.posts}
          columns={columns}
          // defaultPageSize={12}
          style={{
            height: "500px"
          }}
          showPagination={false}
          resizable={false}
          defaultSorted={[{ id: "UpdatedAt", desc: false }]}

        />


      </React.Fragment>

    );
  }
}

export default withRouter(TableData)
